import React from 'react';
import app from "@firebase/app";
import './Inspection.css'
import CustomCarousel from "../../elements/Carousel/Carousel";
import * as STATUS from '../../../constants/status'
import {
    AdvancedIcon,
    BasicIcon,
    CarIcon,
    DateIcon,
    DocIcon,
    EmailIcon,
    PhoneIcon,
    SendIcon,
    SendIconBlack,
    TransportIcon,
    CheckedIcon,
    UnCheckedIcon,
    FlagIcon, 
    EditIcon,
    BinIcon
} from "../../elements/Icons/Icons";
import { compose } from "recompose";
import Input from "reactstrap/es/Input";
import { withAuthorization } from "../../../session";
import { FormGroup, Label, Button } from "reactstrap";
import ConfirmationModal from "../../elements/modal/ConfirmModal";
import { withRouter } from "react-router-dom";
import MaterialIcon from "material-icons-react";
import TextModal from "../../../components/elements/text-modal/TextModal";
import {
    sendEmail
} from '../../../api/vehicle';
import { isEmailValid } from '../../../static/utils'

const getEvaluationCollection = () => app.firestore().collection("inspections");

class Inspection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendEmailModal: false,
            emailAddress: "",
            showSuccessModal: false,
            deleteModal: false,
            showErrorModal:false
        }
    }
    inspectionEdit = (evaluation) => {
        const uid = evaluation.uid || evaluation.objectID
        const eDate = evaluation && evaluation.eDate ? evaluation.eDate : {};
        evaluation = {
            ...evaluation,
            eDate: {
                ...eDate,
                seconds: eDate._seconds || eDate.seconds
            }
        }
        this.props.history.push({
            pathname: `edit/${uid}`,
            evaluation: evaluation,
        });
    };

    deleteModalToggle = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    };

    sendEmailModalToggle = () => {
        const { evaluation } = this.props;
        const { customerEmailAddress, bankNameRep } = evaluation;
        // const emailTextFieldValue = vEmail && customerEmailAddress && vEmail.toLowerCase() === customerEmailAddress.toLowerCase() ? cEmail : customerEmailAddress.concat(", ", vEmail);
        const emailTextFieldValue = customerEmailAddress ? customerEmailAddress : "";
        this.setState(prevState => ({
            sendEmailModal: !prevState.sendEmailModal,
            emailAddress: emailTextFieldValue
        }));
    };

    successModalToggle = () => {
        this.setState(prevState => ({
            showSuccessModal: !prevState.showSuccessModal,
        }));
    };

    errorModalToggle = () => {
        this.setState(prevState => ({
            showErrorModal: !prevState.showErrorModal,
        }));
    };


    sendEmail = () => {
        const { emailAddress } = this.state;
        const { evaluation } = this.props;

        const isValidEmail = this.isAllEmailsValid(emailAddress);
        if (isValidEmail) {
            const emailId = emailAddress.replace(/(^\s*,)|(,\s*$)/g, '')
            let id = ''
            if(typeof evaluation.uid === 'undefined'){
                id = evaluation.objectID
            } else {
                id = evaluation.uid
            }
            sendEmail({ "inspectionId": id, emailId }).then((response) => {
                if(response){
                    this.setState({
                        sendEmailModal: false,
                        emailAddress: ''
                    }, () => {
                        this.successModalToggle();
                    })
                } else {
                    this.setState({
                        sendEmailModal: false,
                    }, () => {
                        this.errorModalToggle();
                    }) 
                }
            }).catch(err => {
                this.setState({
                    sendEmailModal: false,
                }, () => {
                    this.errorModalToggle();
                })
            })
        }
    }

    inspectionDelete = (evaluation) => {
        const SELF = this;
        const uid = evaluation.uid || evaluation.objectID
        return new Promise(function (resolve, reject) {
            const docRef = getEvaluationCollection().doc(uid);
            docRef.delete().then(() => {
                SELF.deleteModalToggle();
                resolve('deleted successfully')
                window.location.reload();
            }).catch(() => {
                reject("Object not created");
            });
        })
        
    }; 

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    renderModalBody = () => {
        const { evaluation } = this.props;
        const { customerEmailAddress, bankNameRep } = evaluation;
        const { sendEmailModal, emailAddress, showSuccessModal } = this.state;
        const isValidEmail = this.isAllEmailsValid(emailAddress);
        return <React.Fragment>
            <FormGroup className='createFormGroup d-flex flex-row createFormGroup '>
                <Label for="chooseLocation"><b>Email Address</b></Label>
                <Input type="text" name="emailAddress" id="emailAddress" value={emailAddress}
                    onChange={event => this.onInputChange(event)} />
            </FormGroup>
            <FormGroup className='createFormGroup d-flex flex-row createFormGroup '>
                <Label for="chooseLocation"><i>* Multiple addresses can input separate by commas.</i></Label>
            </FormGroup>
            {bankNameRep && customerEmailAddress && bankNameRep.toLowerCase() !== customerEmailAddress.toLowerCase() && <FormGroup className='createFormGroup d-flex flex-row createFormGroup '>
                <Label for="chooseLocation"></Label>
            </FormGroup>}
            {emailAddress && !isValidEmail && <span className="error" style={{
                marginLeft: '10px',
                fontSize: 13
            }}>Email address is Invalid</span>}

        </React.Fragment>
    }

    isAllEmailsValid = () => {
        const { emailAddress} = this.state;
        const emailIds = emailAddress.replace(/(^\s*,)|(,\s*$)/g, '');
        const allEmails = emailIds.split(",");
        for (let i=0;i <allEmails.length;i++ ) {
            const isValidEmail = isEmailValid(allEmails[i].trim());
            if(!isValidEmail) {
                return false
            }
        }
        return true;
    }

    render() {
        const { evaluation } = this.props;
        const { customerEmailAddress, bankNameRep } = evaluation;
        const { sendEmailModal, emailAddress, showSuccessModal, deleteModal,showErrorModal } = this.state;
        if (!evaluation || !evaluation.eDate) {
            return null
       }
        const isVehicleOwner = evaluation.cName === evaluation.eName
            && evaluation.cEmail === evaluation.vEmail
            && evaluation.cPhCode === evaluation.vPhCode
            && evaluation.cPhNumber === evaluation.vPhNumber;
        const seconds = evaluation.eDate._seconds || evaluation.eDate.seconds
        const theDate = evaluation && new Date(seconds * 1000);
        const body = this.renderModalBody();

        return (
            <div className={'inspection'}>
                <p className={'inspectionTitle'}><CarIcon /> {evaluation.vYear} {evaluation.vMake} {evaluation.vModel}
                </p>
                <div className={'inspectionWrapper'}>
                    <div className="info-wrapper">
                        <div className='customer-info'>
                            {!isVehicleOwner && <MaterialIcon icon="info" color='#454F63' />}
                            <div className='customer-info-notification'>
                                <p className={'inspectionName'}><b>Evaluations Details</b></p>
                                <p className={'inspectionName'}>{evaluation.eName}</p>
                                {/* <p className={'inspectionPhone'}><PhoneIcon />{`+${evaluation.vPhCode}${evaluation.vPhNumber}`}</p>
                                <p className={'inspectionEmail'}><EmailIcon /> {evaluation.vEmail}</p> */}
                                <p className={'inspectionPhone'}>{evaluation.bankName}</p>
                                <p className={'inspectionEmail'}><EmailIcon /> {evaluation.bankNameRep}</p>
                            </div>
                        </div>
                        <p className={'inspectionName'}>{evaluation.customerFullName}</p>
                    </div>
                    {/* <p className={'inspectionPhone'}><PhoneIcon />{`+${evaluation.cPhCode}${evaluation.customerPhone}`}</p> */}
                    <p className={'inspectionPhone'}><PhoneIcon />{`+${evaluation.customerPhone}`}</p>
                    <p className={'inspectionEmail'}><EmailIcon /> {evaluation.customerEmailAddress}</p>
                    <div className='d-flex carInfo'>
                        <div className='carInfoRow1'><p>YEAR</p><p>{evaluation.vYear}</p></div>
                        <div className='carInfoRow1'><p>MAKE</p><p>{evaluation.vMake}</p></div>
                        <div className='carInfoRow1'><p>MODEL</p><p>{evaluation.vModel}</p></div>
                    </div>
                    <div className='d-flex carInfo'>
                        <div className='d-flex flex-column align-items-center justify-content-between'>
                            <TransportIcon />
                            <p className={'carPlate'}>{evaluation.vPlate}</p>
                        </div>
                        <div><p className="distanceUnit">{evaluation.distanceUnit || 'KMS'}</p><p>{evaluation.vKM != null ? evaluation.vKM : '-'}</p></div>
                        <div><p>COLOUR</p><p className={'shortString'}>{evaluation.vColour}</p></div>
                    </div>
                    <div className='carImages'>
                        {evaluation.carImages && evaluation.carImages.length > 0 ?
                            <CustomCarousel carImages={evaluation.carImages} />
                            : <img className={'defaultImage'} src={'images/default_car.jpg'} alt={'default-car'} />
                        }
                    </div>
                    <div className={`inspectionType ${evaluation.inspectionType}`}>
                        <p>{evaluation.inspectionType === 'Basic' ? <BasicIcon /> :
                            <AdvancedIcon />}{evaluation.inspectionType === 'Basic' ? 'Standard' : evaluation.inspectionType}</p>
                    </div>
                    <div>
                        <p><CheckedIcon />{evaluation.inspectionRef}</p>
                    </div>

                    <p className={'inspectionDate'}><DateIcon />{` ${theDate.toLocaleDateString("en-GB", {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    })}`}</p>
                    <p className={'inspectionVin'}><DocIcon /> {evaluation.vin}</p>
                    <div className={'inspectionLine'} />
                    {evaluation.status === STATUS.PENDING || evaluation.status === STATUS.PROGRESS
                        ?
                        <div className={'inspectionSendMail'}><FlagIcon /> <b>Status:&nbsp;</b> {evaluation.status}</div>
                        : <div className={'inspectionSendMail active'}><FlagIcon />
                            <a href={evaluation.pdfURLShort} target='_blank'><b>Status:&nbsp;</b> {evaluation.status}</a>&nbsp;&nbsp;
                          </div>
                    }
                    {evaluation.status === STATUS.PENDING || evaluation.status === STATUS.PROGRESS
                        ?
                        <div className={'inspectionSendMail'}></div>
                        : <div className={'inspectionSendMail active'}>
                            <SendIconBlack /><Button onClick={this.sendEmailModalToggle} style={{
                                color: 'black',
                                paddingLeft: '0px',
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                            }}>
                                <p>Resend</p>
                            </Button>
                        </div>
                    }
                    {/* <TextModal
                    header={'Warning!'}
                    body={'Do you want leave this page?'}
                    modal={true}
                    toggle={() => {}}
                    onAccept={() => {}}
                    showButton={true}
                /> */}

                </div>
                <ConfirmationModal toggle={this.sendEmailModalToggle}
                    onAccept={this.sendEmail}
                    modal={sendEmailModal}
                    buttons={[null, <SendIcon />]}
                    header={'Resend PDF Report'}
                    body={body}
                    showButton={true}
                />
                <ConfirmationModal toggle={this.successModalToggle}
                    modal={showSuccessModal}
                    header={'Success'}
                    body={`Report sent Successfully`}
                    showButton={false}
                />
                <ConfirmationModal toggle={this.errorModalToggle}
                    modal={showErrorModal}
                    header={'Error'}
                    body={`Your PDF Report couldn’t be sent. We’re sorry. Please try again later.`}
                    showButton={false}
                />
            </div>
        );
    }
}

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition), withRouter
)(Inspection);
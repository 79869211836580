import React, {Component} from 'react';
import {Table, Input} from 'reactstrap';
import Loader from "../../elements/Loader/Loader";
import MaterialIcon from "material-icons-react";
import ConfirmationModal from "../../elements/modal/ConfirmModal";
import BankRow from './BankRow'
import { getAllBanks, updateBank, createBank, deleteBank} from "../../../firebase/api/banks";
import AddBankForm from "../BankForm/BankForm";


class BankTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
        showLoader: false,
        banks: [],
        addBank: false,
        showSuccessModal: false,
        deletedBank: "",
        dto: {
          uid: "",
          emails: [],
        }
    }
  }

  componentDidMount() {
    const {banks, locations} = this.state;
    this.setState({showLoader: true});
    getAllBanks().then(results => {
        if (results) {
            results.forEach(function (doc) {
                const data = doc.data();
                data.uid = doc.ref.id;
                banks.push(data);
            });
            this.setState({banks, showLoader: false});
        }
    })
  }

  onInputChange = event => {
    const {dto} = this.state;
    // dto[event.target.name] = event.target.value;
    if(event.target.name === 'emails'){
      dto.emails[0]=event.target.value;
    } else {
      dto[event.target.name] = event.target.value;

    }
    this.setState({dto});
  };

  editBank = (dto, uid, email, index) => {
    const {banks} = this.state;
    updateBank(dto, uid, email).then(result => {
        if(result){
          banks[index].uid = dto.uid;
          banks[index].email = dto.email;
          this.setState({banks});
        }
    })
  };

  addNewBankRow = (event, dto) => {
    const {banks} = this.state;
    banks.push({...dto});
    this.setState({banks});
  };

  addNewBank = (event, dto) => {
    event.preventDefault();
    this.setState({showLoader: true})
    return new Promise((resolve, reject) => {
      // let obj = {
      //   uid:dto.uid,
      //   emails:[dto.email]
      // }
      createBank({...dto}).then((result) => {
          if (result) {
              this.addNewBankRow(event, dto);
              dto.uid = "";
              this.setState({dto, showLoader: false});
              resolve(result);
          } else {
              result.data.error && this.setState({error: result.data.error});
              this.setState({showLoader: false});
              reject(result);
          }
      });
    });
  };

  deleteBank = (uid) => {
    return new Promise((resolve, reject) => {
      const {banks} = this.state;
      deleteBank(uid).then((response) => {
        const filterBanks = banks.filter(tmpBank => tmpBank.uid !== uid)
        this.setState({banks: filterBanks, deletedBank: {uid}});
        this.successModalToggle();
        resolve(true);
      })
    })
  };

  successModalToggle = () => {
    this.setState(prevState => ({
        showSuccessModal: !prevState.showSuccessModal,
        deletedUser: ''
    }));
  }

  render() {
    const {bank} = this.props;
    const {showLoader, banks, addBank, dto, showSuccessModal, deletedBank} = this.state;
    const isButtonDisabled = dto.uid;
    return (
      <div className='banks-table'>
        <AddBankForm addNewBank={(event, dto) => this.addNewBank(event, dto)} />
        {showLoader ? <Loader/> :
        <Table bordered>
          <thead>
            <tr>
              <th style={{width: '40%'}}>Name</th>
              <th style={{width: '40%'}}>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {banks && banks.length > 0 && banks.map((bank, index) => (
              <BankRow
                key={index}
                index={index}
                bank={bank}
                bank={bank}
                deleteBank={this.deleteBank}
                editBank={this.editBank}
              />
            ))}

          </tbody>

        </Table>}
                            
                            <ConfirmationModal toggle={this.successModalToggle}
                                                       modal={showSuccessModal}
                                                       header={'Success'}
                                                       body={`${deletedBank.uid} Deleted Successfully`}
                                                       showButton={false}
                                    />
      </div>
    )

  }
}

export default BankTable;

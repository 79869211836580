import React from 'react';
import './BankForm.css'
import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
import {USER_ROLES} from "../../../configs";
import {doCreateUserWithEmailAndPassword} from "../../../firebase/api/auth";
import {ifStringEmpty} from "../../../static/utils";
import {createUser, createUserCloud, getUserByUid} from "../../../firebase/api/users";
import Loader from "../../elements/Loader/Loader";

class AddBankForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddbank: false,
            showLoader: false,
            dto: {
                uid: "",
                emails:[]
            },
            error: "",
        };
    }

    showBankToggle = () => {
        this.setState(prevState => ({
            showAddbank: !prevState.showAddbank
        }));
    };

    onInputChange = event => {
        const {dto} = this.state;
       
        if(event.target.name === 'emails'){
            if(dto.emails && dto.emails.length >= 1){
                dto.emails[0]=  event.target.value
            } else {
                dto.emails.push(event.target.value);
            }
          } else {
            dto[event.target.name] = event.target.value;
          }
        this.setState({dto});
    };

    addBank = event => {
        event.preventDefault();
        const {addNewBank} = this.props;
        const {dto} = this.state;
      
        this.setState({showLoader: true});
        
        addNewBank(event, dto).then((result) => {
            dto.uid = "";
            this.setState({dto, showLoader: false, showAddbank: false});
        }).catch((result) => {
            this.setState({showLoader: false});
            if(result && result.data && result.data.error){
                result.data.error && this.setState({error: result.data.error});
                
            }
           
        });
    };

    render() {
        const {showAddbank, dto, error, showLoader} = this.state;
        const isValid = dto.uid;
        return (
            <div className='new-bank-wrapper'>
                <div className='new-user-buttons'>
                    <Button color="danger"
                            onClick={() => this.showBankToggle()}>{showAddbank ? "Back" : "Add Bank"}</Button>
                    {showAddbank &&
                    <Button color="info" disabled={!isValid} className='ml-2' onClick={event => this.addBank(event)}>Add
                        Bank</Button>}
                </div>
                {showAddbank && <Form className="add-user-form">
                    {showLoader ? <div className='w-100' style={{minHeight: '172px'}}>
                            <span className='m-auto'>
                                <Loader/>
                            </span>
                        </div>
                        : <>
                            <div className='w-100 justify-content-between flex-wrap'>
                                <FormGroup className="w-50 pr-1">
                                    <Label for="exampleEmail">Name</Label>
                                    <Input type="text"
                                           name="uid"
                                           id="exampleEmail"
                                           placeholder="Name"
                                           value={dto.uid}
                                           onChange={event => this.onInputChange(event)}
                                    />
                                    <Label for="exampleEmail">Email</Label>
                                    <Input type="text"
                                           name="emails"
                                           id="exampleEmail"
                                           placeholder="Email"
                                           value={dto.email}
                                           onChange={event => this.onInputChange(event)}
                                    />

                                </FormGroup>
                            </div>
                        </>}
                    <p className="error-message">{error}</p>
                </Form>}
            </div>
        );
    }
}

export default AddBankForm;
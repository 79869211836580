import app from "@firebase/app";
import {executeSearchQuery} from "./firestoreUtils";


const getBankCollection = () => app.firestore().collection("banks");

export const getAllBanks = () => {
    const query = buildSearchQuery();
    return executeSearchQuery(query);
};

export const createBank = (dto) => {
    return new Promise(function (resolve, reject) {
        const docRef = getBankCollection().doc(dto.uid);
        docRef.set({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

export const updateBank = (dto, uid) => {
    return new Promise(function (resolve, reject) {
        deleteBank(uid);
        createBank(dto).then((result) => {
            if (result) {
                resolve(result)
            } else {
                reject("Object not created");
            }
        });
    })
};

export const deleteBank = (uid) => {
    return getBankCollection().doc(uid).delete()
};


const buildSearchQuery = () => {
    return getBankCollection();
};


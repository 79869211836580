import React, { Component } from "react";
import { compose } from "recompose";
// import './Grid.css'
import { withAuthorization } from "../../../session/index";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import SendPdf from "./SendPdf";
import "ag-grid-enterprise";
import DateSelector from "../DateSelector/DateSelector";

import { Row,Col,Input } from "reactstrap";
import { getLocaleDateString } from "../../../static/utils";
import "./Evaluation.css";
let carMappings = [
  'abc',
  'efc'
]
class Evaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      searchResults: false,
      columnDefs: [
        {
          headerName: "Date",
          field: "theDate",
          filter: "agDateColumnFilter",
          sort: 'desc' ,
          unSortIcon: true,
          filterParams: {
            comparator: function(filterLocalDateAtMidnight, cellValue) {
              var dateAsString = cellValue;
              if (dateAsString == null) return -1;
              var dateParts = dateAsString.split("/");
              var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
                return 0;
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            browserDatePicker: true
          },
          minWidth:150,
          // width:300

        },
        {
          headerName: "Vehicle",
          field: "vehicle",
          filter: true,
          width: 400,
          minWidth:200,
          unSortIcon: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: "Customer Name",
          field: "customerFullName",
          filter: true,
          width: 400,
          minWidth:150,
          unSortIcon: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: "Customer Phone Number",
          field: "customerPhone",
          filter: true,
          width: 400,
          minWidth:150,
          unSortIcon: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: "Customer Email Address",
          field: "customerEmailAddress",
          filter: true,
          width: 500,
          minWidth:200,
          unSortIcon: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: "Plate Number",
          field: "vPlate",
          filter: 'agTextColumnFilter',
          width: 150,
          unSortIcon: true,
          minWidth:150,

        },
        {
          headerName: "Evaluator Name",
          field: "eName",
          unSortIcon: true,
          width: 260,
          minWidth:150,
          cellEditor: 'select',
          filter: "agSetColumnFilter",

          filterParams: { suppressMiniFilter: true }
        //   cellEditorParams: {
        //     values: this.carMappings
        //  },
        },
        {
          headerName: "Bank Name",
          field: "bankName",
          filter: true,
          unSortIcon: true,
          width:100,
          minWidth:200,
        },
        {
          headerName: "Price (AED)",
          field: "price",
          sortable: true,
          filter: "agNumberColumnFilter",
          unSortIcon: true,
          valueFormatter: this.currencyFormatter,
          minWidth:150,
          // width:110
        },
        {
          headerName: "Action",
          field: "pdfURLShort",
          cellRenderer: "sendPdf",
          width:80,
          minWidth:80,
          filter:false,
          sortable: false,
          cellRenderer:
            function(params) {
              //console.log("paramsparams",params)
              if(params.data.pdfURLShort == "https://undefined"){
                return '<a href="'+params.data.pdfURL+'" target="_blank">View</a>'
              }else{
                return '<a href="'+params.data.pdfURLShort+'" target="_blank">View</a>'
              }
            }
        }
      ],
      context: { componentParent: this },
      gridOptions: {
        floatingFilter: true,
        defaultColDef: {
          editable: true,
          // enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          sortable: true,
          resizable: true,
           filter: true
        },
        suppressRowClickSelection: true,
        groupSelectsChildren: true,
        debug: true,
        rowSelection: "multiple",
        // rowGroupPanelShow: "always",
        pivotPanelShow: "always",
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 20,
        // autoGroupColumnDef: autoGroupColumnDef,
        paginationNumberFormatter: function(params) {
          return params.value.toLocaleString();
        }
      },
      frameworkComponents: {
        sendPdf: SendPdf,
      },
    

    };
  }


   currencyFormatter = (params) => {
    return  this.formatNumber(params.value);
  }
   formatNumber = (number) => {
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return Math.floor(number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}


  onPageSizeChanged(newPageSize) {
    // var value = document.getElementById('page-size').value;
    this.state.gridOptions.api.paginationSetPageSize(Number(newPageSize));
  }

   numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onDateChange = (event) =>{
    const searchedDate = event.target.value;
    let getFilteredEvaluation = this.props.evaluations;
    getFilteredEvaluation.forEach(function(evaluation) {
      let year = evaluation.vYear.toString();
      let vehicle = year.concat(" ", evaluation.vMake, " ", evaluation.vModel);
      evaluation.vehicle = vehicle;
      const seconds =
        evaluation && (evaluation.eDate._seconds || evaluation.eDate.seconds);
      evaluation.theDate = getLocaleDateString(seconds);
    });
    if(searchedDate){
      var array = searchedDate.split("-");
      let year = array[0];
      let month = array[1];
      let day = array[2];
      let updatedDate = day.concat("/", month, "/", year);
  
      // const updatedDate = searchedDate.replace("-", "/");
      const filteredData = getFilteredEvaluation.filter(data => data.theDate === updatedDate);
      // if(filteredData.length === 0){
        this.setState({
          searchResults: true
        })
      // }
      this.setState({
        rowData: filteredData
      })
      // this.state.rowData.filter
    }  else {
      this.setState({
        searchResults: false,
        rowData:[]
      })
    }
    
  }
  static getDerivedStateFromProps(props,state) {
    let getFilteredEvaluation = props.evaluations;
    if(props && props.evaluations){
      if (props.evaluations.length !== state.rowData.length && !state.searchResults ) {
        getFilteredEvaluation.forEach(function(evaluation) {
          let year = evaluation.vYear.toString();
          let vehicle = year.concat(" ", evaluation.vMake, " ", evaluation.vModel);
          evaluation.vehicle = vehicle;
          const seconds =
            evaluation && (evaluation.eDate._seconds || evaluation.eDate.seconds);
          evaluation.theDate = getLocaleDateString(seconds);
        });

        return {
          rowData: getFilteredEvaluation,
        };
      }
    }
   
 
   
  }


  render() {
    const { evaluations, onInputChange, search, setDateRange, dateRange } = this.props;
    const {rowData} = this.state;
    const getFilteredEvaluation = evaluations;
    getFilteredEvaluation.forEach(function(evaluation) {
      let year = evaluation.vYear.toString();
      let vehicle = year.concat(" ", evaluation.vMake, " ", evaluation.vModel);
      evaluation.vehicle = vehicle;
      const seconds =
        evaluation && (evaluation.eDate._seconds || evaluation.eDate.seconds);
      evaluation.theDate = getLocaleDateString(seconds);
    });

    return (
      <div
        style={{
          minHeight: 200,
          height: 135 + rowData.length * 27,
          marginTop: "20px",
          background: "#F5F6F7"
        }}
        className="ag-theme-balham"
      >
        <div className="entry-dropdown justify-content-between py-2 pl-2 pr-0">
          <div className="dropdown-wrap">
            Show:
            <Input
              className="mx-2"
              type="select"
              onChange={e => this.onPageSizeChanged(e.target.value)}
              id="page-size"
            >
              <option value="select">Select</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </Input>
            entries
          </div>
          {/* {value && ( */}
          {/* <div className="search-bar grid d-flex"> */}
          {/* <Row className="mr-0">
            <Col xs={6} className="pr-2"> */}
          
          <div className="d-flex">
            <div className="pr-3">
            <Input
              name="text"
              value={search.text}
              className="search"
              placeholder="Search"
              onChange={event => onInputChange(event)}
            />
            </div>
            {/* </Col>
            <Col xs={6} className="pl-2 pr-0"> */}
            <DateSelector setDateRange={setDateRange} dateRange={dateRange} />
            </div>
             {/* <Input
              name="date"
              type="date"
              className="date"
              placeholder="date"
              // value={ moment(this.state.rowData.requested_order_ship_date).format("DD-MMM-YYYY") } 
              data-date-format="DD MM YYYY" 
              onChange={event => this.onDateChange(event)}
            /> */}
            {/* </Col>
            </Row> */}
          {/* </div> */}
          {/* )} */}
        </div>
        <AgGridReact
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}

          // rowData={getFilteredEvaluation}
          gridOptions={this.state.gridOptions}
          frameworkComponents={this.state.frameworkComponents}
          onGridReady={this.onGridReady}
          context={this.state.context}
        />
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition))(Evaluation);

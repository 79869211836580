import React, { Component } from 'react';
import './FaultCodesTable.css';
import { deleteReportBasicItem, editReportBasicItem, getAllReportBasic, getReportBasicItems, editWheelTyreItem, deleteWheelTyreItem  } from "../../../firebase/api/report";
import FaultCodeRow from "./FaultCodeRow";
import Loader from "../../elements/Loader/Loader";
import { FormGroup, Input, Label, Table } from "reactstrap";
import MaterialIcon from "material-icons-react";
import { createLocation } from "../../../firebase/api/locations";
import { isCategoryNameValid } from '../../../static/utils';


class FaultCodesBasicTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            itemsDisabled: false,
            error: false,
            addField: false,
            noItems: false,
            reports: {},
            items: {},
            dto: {
                name: ''
            }
        }
    }


    getItems = (uid) => {
        const items = {};
        this.setState({ itemsDisabled: true, noItems: false });
        getReportBasicItems(uid).then(results => {
            if (results) {
                results.forEach(result => {
                    items[result.ref.id] = result.data();
                });
            } else {
                this.setState({ noItems: true })
            }
            this.setState({ items, itemsDisabled: false, activeReportId: uid }, () => {
                const itemsKey = Object.keys(items);
                this.getField(itemsKey[0]);
            })
        });
    };

    addNewReport = async () => {
        const { dto } = this.state;
        const { activeItemId, activeItem, activeReportId } = this.props;
        const issues = activeItem.issues
        let result = '';
        issues.push(dto.name);
        const updatedItem = {
            ...activeItem,
            issues
        }
        if (activeReportId === 'Wheels and Tyres') {
            result = await editWheelTyreItem(activeItemId, updatedItem)
        } else {
            result = await editReportBasicItem(activeReportId, activeItemId, updatedItem)
        }
        
        if (result) {
            this.setState({ activeItem }, () => this.resetInputs());
        }
    };

    editReport = async (dto, index) => {
        const { activeItemId, activeItem, activeReportId } = this.props;
        const issues = activeItem.issues
        issues[index] = dto.name;
        let result = '';
        const updatedItem = {
            ...activeItem,
            issues
        }
        if (activeReportId === 'Wheels and Tyres') {
            result = await editWheelTyreItem(activeItemId, updatedItem)
        } else {
            result = await editReportBasicItem(activeReportId, activeItemId, updatedItem)
        }
       
        if (result) {
            this.setState({ activeItem });
        }
    };

    deleteReport = async (index, callback) => {
        const { activeItemId, activeReportId, activeItem } = this.props;
        const issues = activeItem.issues;
        let result = '';
        issues.splice(index, 1);
        const updatedItem = {
            ...activeItem,
            issues
        }
        if (activeReportId === 'Wheels and Tyres') {
            result = await editWheelTyreItem(activeItemId, updatedItem)
        } else {
            result = await deleteReportBasicItem(activeReportId, activeItemId, updatedItem)
        }
        
        if (result) {
            this.setState({ activeItem: activeItem }, () => callback());
        }
    };

    resetInputs = () => {
        const { dto } = this.state;
        dto.name = "";
        this.setState({ dto });
    };

    getField = (uid) => {
        const { items } = this.state;
        const activeItem = items[uid];
        this.setState({ activeItem, activeItemId: uid })
    };

    onInputChange = event => {
        const { dto } = this.state;
        dto[event.target.name] = event.target.value;
        this.setState({ dto });
    };

    onEnterPressAdd = (event) => {
        const { dto } = this.state;
        if (event.key === 'Enter' && dto.name) {
            this.addNewReport()
        }
    };

    isAllItemNamesValid = (itemsKey) => {
        if (itemsKey && itemsKey.length > 0) {
            for (const key of itemsKey) {
                if (!isCategoryNameValid(key)) {
                    return false;
                }
            }

        }
        return true;
    }

    hasError = (isValid) => {        
        this.setState({
            error: !isValid
        })
    }

    render() {
        const { reports, items, error, showLoader, addField, dto, itemsDisabled, noItems } = this.state;

        const { activeItem, activeItemId, activeReportId } = this.props;
        const reportsKey = Object.keys(reports);
        const itemsKey = Object.keys(items);

        const isButtonDisabled = isCategoryNameValid(dto.name);
        const isNamesValid = this.isAllItemNamesValid(itemsKey);
        return (
            <tr>
                <td colSpan="2">
                    <div className={'fault-codes-table c-bordered'}>
                        {showLoader
                            ? <Loader />
                            : <Table bordered>
                                <thead>
                                    <tr>
                                        <th style={{ width: "85%" }}>Issues</th>
                                        <th style={{ width: "15%" }}>Options</th>
                                    </tr>
                                </thead>
                                {noItems
                                    ? <tbody>
                                        <tr>
                                            <td />
                                            <td />
                                        </tr>
                                    </tbody>
                                    : <tbody>
                                        {activeItem && activeItem.issues && activeItem.issues.map((item, index) => (
                                            <FaultCodeRow
                                                editReport={this.editReport}
                                                deleteReport={this.deleteReport}
                                                key={index}
                                                index={index}
                                                hasError={this.hasError}
                                                item={item} />
                                        ))}
                                        <tr>
                                            <td>{addField && <Input name={'name'} placeholder={'Field'} value={dto.name}
                                                onChange={event => this.onInputChange(event)}
                                                onKeyPress={event => this.onEnterPressAdd(event)}
                                            />}</td>
                                            <td>
                                                {addField
                                                    ? <div className='d-flex'>

                                                        <button className='option-button'
                                                            onClick={() => this.addNewReport()} disabled={!isButtonDisabled}>
                                                            <MaterialIcon icon="note" />
                                                        </button>

                                                        <button className='option-button'
                                                            onClick={() => {
                                                                this.setState({ addField: !addField }, () => this.resetInputs());
                                                            }}>
                                                            <MaterialIcon icon="reply" />
                                                        </button>

                                                    </div>
                                                    : <button className='option-button'
                                                        onClick={() => this.setState({ addField: !addField })}
                                                    >
                                                        <MaterialIcon icon="note_add" />
                                                    </button>}
                                            </td>
                                        </tr>
                                    </tbody>}
                            </Table>}

                            {(!isNamesValid || error) && <p className="error" style={{
                                    marginLeft: '12px'
                                }}>{'Fault category name should not contain double spaces, special characters except -, &'}.</p>}
                    </div>

                </td>
            </tr>

        )
    }
}

export default FaultCodesBasicTable
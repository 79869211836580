import app from "@firebase/app";

export const doSignInWithEmailAndPassword = (email, password) =>
    app.auth().signInWithEmailAndPassword(email, password);

export const onAuthUserListener = (next, fallback) =>
    app.auth().onAuthStateChanged(authUser => {
        authUser ? next(authUser) : fallback();
    });

export const reauthenticate = (currentPassword) => {
    const user = app.auth().currentUser;
    const cred = app.auth.EmailAuthProvider.credential(
        user.email, currentPassword);
    return user.reauthenticateAndRetrieveDataWithCredential(cred);
};

export const doCreateUserWithEmailAndPassword = (email, password) =>
    app.auth().createUserWithEmailAndPassword(email, password);

export const doSignOut = () => app.auth().signOut();

export const doPasswordReset = email => app.auth().sendPasswordResetEmail(email);

export const doPasswordUpdate = password => app.auth().currentUser.updatePassword(password);

export const doEmailUpdate = email => app.auth().currentUser.updateEmail(email);

export const doSendEmailVerification = () =>
    app.auth().currentUser.sendEmailVerification({
        url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });


import React from 'react';
import {Modal} from 'reactstrap';
import './CircularLoader.css';

class CircularLoader extends React.Component {

  render() {
    return (<div className="m-loader"  >
      <Modal isOpen={true} className="m-loader modal-dialog-centered">
        <div className="lds-roller">
          <div>
          </div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </Modal></div>)

  }
}

export default CircularLoader

import React from "react";

import AuthUserContext from "./context";
import {onAuthUserListener} from "../firebase/api/auth";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import {getUserByUid} from "../firebase/api/users";

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                authUser: JSON.parse(localStorage.getItem("algoDriven-authUser")),
            };
        }

        componentDidMount() {
            this.listener = onAuthUserListener(
                authUser => {

                    getUserByUid(authUser.uid).then(snapshot => {
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            role: snapshot ? snapshot.role : 'anonymous'
                        };

                        localStorage.setItem("algoDriven-authUser", JSON.stringify(authUser));
                        this.setState({authUser});
                        this.props.history.location.pathname === '/create'
                            ? this.props.history.push(ROUTES.CREATE)
                            : this.props.history.push(ROUTES.BOOKING);

                    });
                },
                () => {
                    localStorage.removeItem("algoDriven-authUser");
                    this.setState({authUser: null});
                },
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return withRouter(WithAuthentication);
};

export default withAuthentication;

import React from 'react';
import '../../create/CustomerDetails/CustomerDetails.css';
import {Form, FormGroup, Label} from "reactstrap";
import { isStringValid,isEmailValid,isNumberInvalid,isColorValid, isPlateEngineNumberValid } from '../../../static/utils';

const ErrorComponent = ({value, name, type}) => {
        if (type === 'string')
        return (
            value !== '' && !isStringValid(value) && <span className="error">{name} should be String</span>
        );

        if (type === 'email')
        return (
            value !== '' && !isEmailValid(value) && <span className="error">{name} is invalid</span>
        );

        if (type === 'number')
        return (
            value !== '' && isNumberInvalid(value) && <span className="error">{name} should be integer</span>
        );
        if (type === 'color')
        return (
            value !== '' && !isColorValid(value.trim()) && <span className="error">Colour is invalid</span>
        );
        if (type === 'plateNumber' || type === 'engineNumber')
        return (
            value !== '' && !isPlateEngineNumberValid(value.trim()) && <span className="error">{name} should be alphanumeric</span>
        );
        return null;
    };
    
export default ErrorComponent;

import React from 'react';

export const DeleteIcon = () => {
    return(<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14.649px" height="14.649px" version="1.1"  viewBox="0 0 512 512" xmlSpace="preserve">
           
                        <polygon  fill="#fff" points="353.574,176.526 313.496,175.056 304.807,412.34 344.885,413.804    " />
                        <rect  fill="#fff" x="235.948" y="175.791" width="40.104" height="237.285" />
                        <polygon  fill="#fff" points="207.186,412.334 198.497,175.049 158.419,176.52 167.109,413.804    " />
                        <path fill="#fff" d="M17.379,76.867v40.104h41.789L92.32,493.706C93.229,504.059,101.899,512,112.292,512h286.74     c10.394,0,19.07-7.947,19.972-18.301l33.153-376.728h42.464V76.867H17.379z M380.665,471.896H130.654L99.426,116.971h312.474     L380.665,471.896z" />
           
                    <path d="M321.504,0H190.496c-18.428,0-33.42,14.992-33.42,33.42v63.499h40.104V40.104h117.64v56.815h40.104V33.42    C354.924,14.992,339.932,0,321.504,0z" fill="#fff" />
             

        </svg>

    )
}

export const BinIcon = () => {
    return(<svg height="22px" viewBox="-48 0 407 407" width="16.778px" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path d="m89.199219 37c0-12.132812 9.46875-21 21.601562-21h88.800781c12.128907 0 21.597657 8.867188 21.597657 21v23h16v-23c0-20.953125-16.644531-37-37.597657-37h-88.800781c-20.953125 0-37.601562 16.046875-37.601562 37v23h16zm0 0"/><path d="m60.601562 407h189.199219c18.242188 0 32.398438-16.046875 32.398438-36v-247h-254v247c0 19.953125 14.15625 36 32.402343 36zm145.597657-244.800781c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm-59 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm-59 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm0 0"/><path d="m20 108h270.398438c11.046874 0 20-8.953125 20-20s-8.953126-20-20-20h-270.398438c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20zm0 0"/></svg>

    )
}


export const BlackKey = () => {
    return (
        <svg width="72" height="72" viewBox="0 0 90.585 90.585">
            <defs>
                <filter id="Path_2" x="0" y="0" width="90.585" height="90.585" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="3" result="blur"/>
                    <feFlood floodOpacity="0.102"/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_5" data-name="Group 5" transform="translate(-553 -231)">
                <g id="Group_1" data-name="Group 1" transform="translate(36.292 107)">
                    <g transform="matrix(1, 0, 0, 1, 516.71, 124)" filter="url(#Path_2)">
                        <path id="Path_2-2" data-name="Path 2"
                              d="M12,0H39.326a12,12,0,0,1,12,12V39.326a12,12,0,0,1-12,12H12a12,12,0,0,1-12-12V12A12,12,0,0,1,12,0Z"
                              transform="translate(45.29 6) rotate(45)" fill="#262626"/>
                    </g>
                </g>
                <path id="key"
                      d="M18,6.188a6.191,6.191,0,0,1-7.342,6.08l-.844.95a.844.844,0,0,1-.631.283H7.875v1.406a.844.844,0,0,1-.844.844H5.625v1.406A.844.844,0,0,1,4.781,18H.844A.844.844,0,0,1,0,17.156V14.412a.844.844,0,0,1,.247-.6L5.935,8.127A6.188,6.188,0,1,1,18,6.188ZM11.813,4.5A1.688,1.688,0,1,0,13.5,2.813,1.687,1.687,0,0,0,11.813,4.5Z"
                      transform="translate(589 264)" fill="#fff"/>
            </g>
        </svg>

    )
};

export const SearchIcon = () => {
    return (
        <svg width="20" height="20.836" viewBox="0 0 20 20.836">
            <path id="Icon"
                  d="M18.92,20.836a1.074,1.074,0,0,1-.764-.317l-4.98-4.98A8.371,8.371,0,0,1,2.469,14.522a8.55,8.55,0,0,1,0-12.031A8.4,8.4,0,0,1,5.149.669a8.387,8.387,0,0,1,6.563,0,8.4,8.4,0,0,1,2.68,1.823,8.564,8.564,0,0,1,.4,11.6l4.9,4.9a1.081,1.081,0,0,1-.764,1.845ZM8.43,2.181A6.2,6.2,0,0,0,4,4.034,6.367,6.367,0,0,0,4,12.98a6.229,6.229,0,0,0,8.865,0,6.367,6.367,0,0,0,0-8.946A6.2,6.2,0,0,0,8.43,2.181Z"
                  fill="#fff"/>
        </svg>

    )
};

export const AllIcon = () => {
    return (
        <svg width="12" height="15.714" viewBox="0 0 12 15.714">
            <g id="Group_2635" data-name="Group 2635" transform="translate(-175 -546.286)">
                <path id="path"
                      d="M13.243,13.357a1.691,1.691,0,0,1-3.2,0H3.071a.571.571,0,1,1,0-1.143h6.971a1.691,1.691,0,0,1,3.2,0h.686a.571.571,0,0,1,0,1.143Zm-2.171-.571a.571.571,0,1,0,.571-.571.54.54,0,0,0-.571.571Zm2.857-3.714H6.957a1.691,1.691,0,0,1-3.2,0H3.071a.571.571,0,1,1,0-1.143h.686a1.691,1.691,0,0,1,3.2,0h6.971a.571.571,0,0,1,0,1.143ZM4.786,8.5a.54.54,0,0,0,.571.571.571.571,0,0,0,0-1.143.54.54,0,0,0-.571.571Z"
                      transform="translate(172.5 539.5)" fill="#1b1d1b"/>
                <path id="path-2" data-name="path"
                      d="M13.243,13.357a1.691,1.691,0,0,1-3.2,0H3.071a.571.571,0,1,1,0-1.143h6.971a1.691,1.691,0,0,1,3.2,0h.686a.571.571,0,0,1,0,1.143Zm-2.171-.571a.571.571,0,1,0,.571-.571.54.54,0,0,0-.571.571Zm2.857-3.714H6.957a1.691,1.691,0,0,1-3.2,0H3.071a.571.571,0,1,1,0-1.143h.686a1.691,1.691,0,0,1,3.2,0h6.971a.571.571,0,0,1,0,1.143ZM4.786,8.5a.54.54,0,0,0,.571.571.571.571,0,0,0,0-1.143.54.54,0,0,0-.571.571Z"
                      transform="translate(172.5 547.5)" fill="#1b1d1b"/>
            </g>
        </svg>
    )
};

export const BasicIcon = () => {
    return (
        <svg width="12" height="7.714" viewBox="0 0 12 7.714">
            <path id="path"
                  d="M13.243,13.357a1.691,1.691,0,0,1-3.2,0H3.071a.571.571,0,1,1,0-1.143h6.971a1.691,1.691,0,0,1,3.2,0h.686a.571.571,0,0,1,0,1.143Zm-2.171-.571a.571.571,0,1,0,.571-.571.54.54,0,0,0-.571.571Zm2.857-3.714H6.957a1.691,1.691,0,0,1-3.2,0H3.071a.571.571,0,1,1,0-1.143h.686a1.691,1.691,0,0,1,3.2,0h6.971a.571.571,0,0,1,0,1.143ZM4.786,8.5a.54.54,0,0,0,.571.571.571.571,0,0,0,0-1.143.54.54,0,0,0-.571.571Z"
                  transform="translate(-2.5 -6.786)" fill="#1b1d1b"/>
        </svg>
    )
};

export const AdvancedIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12">
            <path id="path"
                  d="M13.243,13.357a1.691,1.691,0,0,1-3.2,0H3.071a.571.571,0,1,1,0-1.143h6.971a1.691,1.691,0,0,1,3.2,0h.686a.571.571,0,0,1,0,1.143Zm-2.171-.571a.571.571,0,1,0,.571-.571.54.54,0,0,0-.571.571Zm-.971-8a1.691,1.691,0,0,1-3.2,0H3.071A.54.54,0,0,1,2.5,4.214a.54.54,0,0,1,.571-.571H6.9a1.691,1.691,0,0,1,3.2,0h3.829a.54.54,0,0,1,.571.571.54.54,0,0,1-.571.571ZM7.929,4.214a.571.571,0,1,0,1.143,0,.571.571,0,1,0-1.143,0Zm6,4.857H6.957a1.691,1.691,0,0,1-3.2,0H3.071a.571.571,0,1,1,0-1.143h.686a1.691,1.691,0,0,1,3.2,0h6.971a.571.571,0,0,1,0,1.143ZM4.786,8.5a.54.54,0,0,0,.571.571.571.571,0,0,0,0-1.143.54.54,0,0,0-.571.571Z"
                  transform="translate(-2.5 -2.5)" fill="#1b1d1b"/>
        </svg>
    )
};

export const CarIcon = (color) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20">
            <defs>
                <clipPath id="clip-path">
                    <path id="path"
                          d="M27.727,27.273v1.364a1.364,1.364,0,0,1-2.727,0V27.273H15v1.364a1.364,1.364,0,0,1-2.727,0V27.273A2.279,2.279,0,0,1,10,25V19.545a2.256,2.256,0,0,1,1.023-1.886l1.409-5.636A2.742,2.742,0,0,1,15,10H25a2.742,2.742,0,0,1,2.568,2.023l1.409,5.636A2.256,2.256,0,0,1,30,19.545V25a2.279,2.279,0,0,1-2.273,2.273ZM25.818,12.455A.961.961,0,0,0,25,11.818H15a.961.961,0,0,0-.818.636l-1.2,4.818H27.023l-1.2-4.818Zm-14,7.091V25a.456.456,0,0,0,.455.455H27.727A.456.456,0,0,0,28.182,25V19.545a.456.456,0,0,0-.455-.455H12.273a.456.456,0,0,0-.455.455ZM15,23.636a1.364,1.364,0,1,1,1.364-1.364A1.368,1.368,0,0,1,15,23.636Zm10,0a1.364,1.364,0,1,1,1.364-1.364A1.368,1.368,0,0,1,25,23.636Z"
                          transform="translate(-10 -10)" fill="#e00000"/>
                </clipPath>
            </defs>
            <g id="icon" clipPath="url(#clip-path)">
                <rect id="bg" width="24.545" height="24.545" transform="translate(-2.273 -2.273)" fill="#e00000"/>
            </g>
        </svg>
    )
};

export const TransportIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <clipPath id="clip-path">
                    <path id="path"
                          d="M27.727,27.273v1.364a1.364,1.364,0,0,1-2.727,0V27.273H15v1.364a1.364,1.364,0,0,1-2.727,0V27.273A2.279,2.279,0,0,1,10,25V19.545a2.256,2.256,0,0,1,1.023-1.886l1.409-5.636A2.742,2.742,0,0,1,15,10H25a2.742,2.742,0,0,1,2.568,2.023l1.409,5.636A2.256,2.256,0,0,1,30,19.545V25a2.279,2.279,0,0,1-2.273,2.273ZM25.818,12.455A.961.961,0,0,0,25,11.818H15a.961.961,0,0,0-.818.636l-1.2,4.818H27.023l-1.2-4.818Zm-14,7.091V25a.456.456,0,0,0,.455.455H27.727A.456.456,0,0,0,28.182,25V19.545a.456.456,0,0,0-.455-.455H12.273a.456.456,0,0,0-.455.455ZM15,23.636a1.364,1.364,0,1,1,1.364-1.364A1.368,1.368,0,0,1,15,23.636Zm10,0a1.364,1.364,0,1,1,1.364-1.364A1.368,1.368,0,0,1,25,23.636Z"
                          transform="translate(-10 -10)"/>
                </clipPath>
            </defs>
            <g id="icons_transport_car" data-name="icons/transport/car" transform="translate(-173 -503)">
                <rect id="bg" width="24" height="24" transform="translate(173 503)" fill="none"/>
                <g id="icon" transform="translate(175 505)" clipPath="url(#clip-path)">
                    <rect id="bg-2" data-name="bg" width="24.545" height="24.545" transform="translate(-2.273 -2.273)"
                          fill="#fff"/>
                </g>
            </g>
        </svg>

    )
};

export const PhoneIcon = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10">
            <path id="phone-1"
                  d="M10.988,9.774,9.694,8.7a.347.347,0,0,0-.349-.019l-.719.479a.455.455,0,0,1-.255.076,2.991,2.991,0,0,1-.64-.09A3.862,3.862,0,0,1,4.854,6.267a2.988,2.988,0,0,1-.09-.64.454.454,0,0,1,.076-.255l.479-.718A.341.341,0,0,0,5.3,4.3L4.222,3.011a.214.214,0,0,0-.365,0l-.715.873a1.274,1.274,0,0,0-.2,1.018.454.454,0,0,1,.013.053,2.335,2.335,0,0,0,.071.252,5.842,5.842,0,0,0,.327.781A10.387,10.387,0,0,0,5.343,8.654,10.38,10.38,0,0,0,8.01,10.64a5.847,5.847,0,0,0,.782.327,2.411,2.411,0,0,0,.252.071.455.455,0,0,1,.063.017,1.246,1.246,0,0,0,1.007-.2l.874-.714A.213.213,0,0,0,10.988,9.774ZM8.84,7.921A1.247,1.247,0,0,1,10.276,8l1.294,1.078a1.117,1.117,0,0,1-.008,1.767l-.874.714a2.145,2.145,0,0,1-1.847.368q-.169-.039-.334-.094a6.749,6.749,0,0,1-.9-.377A11.291,11.291,0,0,1,4.7,9.3a11.289,11.289,0,0,1-2.157-2.9,6.744,6.744,0,0,1-.377-.9,3.416,3.416,0,0,1-.1-.339,2.171,2.171,0,0,1,.369-1.841l.715-.873a1.119,1.119,0,0,1,1.767-.007L6,3.723a1.242,1.242,0,0,1,.077,1.436l-.393.589a2.687,2.687,0,0,0,.055.31A2.974,2.974,0,0,0,6.2,7.083a2.934,2.934,0,0,0,.715.715,2.976,2.976,0,0,0,1.027.46,2.638,2.638,0,0,0,.31.055l.589-.393Z"
                  transform="translate(-2 -2)" fill="#e00000"/>
        </svg>

    )
};

export const EmailIcon = () => {
    return (
        <svg width="10" height="7.778" viewBox="0 0 10 7.778">
            <path id="envelope"
                  d="M2,9.214A1.178,1.178,0,0,1,3.138,8h7.724A1.179,1.179,0,0,1,12,9.214v5.349a1.178,1.178,0,0,1-1.138,1.214H3.138A1.179,1.179,0,0,1,2,14.563Zm9.091,1.831L7.234,13.521a.429.429,0,0,1-.468,0L2.909,11.045v3.518a.237.237,0,0,0,.229.242h7.724a.22.22,0,0,0,.161-.07.252.252,0,0,0,.068-.172V11.045Zm-.364-.9a.965.965,0,0,0,.364-.686V9.215a.235.235,0,0,0-.226-.242H3.135a.235.235,0,0,0-.226.242V9.46a.969.969,0,0,0,.364.685L7,12.537Z"
                  transform="translate(-2 -8)" fill="#e00000"/>
        </svg>

    )
};

export const DateIcon = () => {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16">
            <path id="calendar-check"
                  d="M12.5,2H11V.375A.375.375,0,0,0,10.625,0H9.375A.375.375,0,0,0,9,.375V2H5V.375A.375.375,0,0,0,4.625,0H3.375A.375.375,0,0,0,3,.375V2H1.5A1.5,1.5,0,0,0,0,3.5v11A1.5,1.5,0,0,0,1.5,16h11A1.5,1.5,0,0,0,14,14.5V3.5A1.5,1.5,0,0,0,12.5,2Zm-.187,12.5H1.688a.188.188,0,0,1-.187-.188V5h11v9.312a.187.187,0,0,1-.187.188ZM10.661,8.23,6.214,12.641a.375.375,0,0,1-.53,0L3.337,10.274a.375.375,0,0,1,0-.53l.71-.7a.375.375,0,0,1,.53,0L5.958,10.43,9.429,6.987a.375.375,0,0,1,.53,0l.7.71a.375.375,0,0,1,0,.53Z"
                  transform="translate(0)" fill="#8a8f9c"/>
        </svg>

    )
};

export const DocIcon = () => {
    return (
        <svg width="13.998" height="16" viewBox="0 0 13.998 16">
            <path id="receipt"
                  d="M10.749,9.006H3.25a.251.251,0,0,0-.25.25v1a.251.251,0,0,0,.25.25h7.5a.251.251,0,0,0,.25-.25v-1A.251.251,0,0,0,10.749,9.006ZM12.523.186l-1.359.978L9.958.3A1.5,1.5,0,0,0,8.205.3L7,1.164,5.793.3A1.5,1.5,0,0,0,4.04.3l-1.206.869L1.475.186A.93.93,0,0,0,0,.942V15.074a.932.932,0,0,0,1.475.756l1.359-.981,1.206.869a1.5,1.5,0,0,0,1.753,0L7,14.849l1.206.869a1.5,1.5,0,0,0,1.753,0l1.206-.869,1.359.978A.93.93,0,0,0,14,15.074V.942A.931.931,0,0,0,12.523.186ZM12.5,13.959,11.164,13,9.08,14.5,7,13,4.912,14.5,2.828,13,1.5,13.959V2.054l1.334.959,2.084-1.5L7,3.013l2.084-1.5,2.084,1.5L12.5,2.054Zm-1.75-8.452H3.25a.251.251,0,0,0-.25.25v1a.251.251,0,0,0,.25.25h7.5a.251.251,0,0,0,.25-.25v-1A.251.251,0,0,0,10.749,5.507Z"
                  transform="translate(0 -0.008)" fill="#8a8f9c"/>
        </svg>

    )
};

export const SendIcon = () => {
    return (
        <svg width="16" height="16.005" viewBox="0 0 16 16.005">
            <path id="paper-plane" style={{    fill: 'currentColor'}}
                  d="M14.886.07.4,8.427a.751.751,0,0,0,.069,1.35l3.322,1.394L12.77,3.258a.187.187,0,0,1,.269.259L5.51,12.69v2.516a.75.75,0,0,0,1.328.494l1.985-2.416,3.894,1.631a.752.752,0,0,0,1.031-.569L16,.845A.75.75,0,0,0,14.886.07Z"
                  transform="translate(-0.01 0.031)" fill="#8a8f9c"/>
        </svg>

    )
};

export const SendIconBlack = () => {
    return (
        <svg width="16" height="16.005" viewBox="0 0 16 16.005">
            <path id="paper-plane"
                  d="M14.886.07.4,8.427a.751.751,0,0,0,.069,1.35l3.322,1.394L12.77,3.258a.187.187,0,0,1,.269.259L5.51,12.69v2.516a.75.75,0,0,0,1.328.494l1.985-2.416,3.894,1.631a.752.752,0,0,0,1.031-.569L16,.845A.75.75,0,0,0,14.886.07Z"
                  transform="translate(-0.01 0.031)" fill="#000000"/>
        </svg>

    )
};

export const CheckedIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16">
            <g id="Group_2636" data-name="Group 2636" transform="translate(-61.631 -594.802)">
                <path id="check-circle"
                      d="M24,16a8,8,0,1,1-8-8A8,8,0,0,1,24,16Zm-8.925,4.236L21.01,14.3a.516.516,0,0,0,0-.73l-.73-.73a.516.516,0,0,0-.73,0L14.71,17.681l-2.26-2.26a.516.516,0,0,0-.73,0l-.73.73a.516.516,0,0,0,0,.73l3.355,3.355a.516.516,0,0,0,.73,0Z"
                      transform="translate(53.631 586.802)" fill="#dc0000"/>
            </g>
        </svg>

    )
};

export const UnCheckedIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16">
            <path id="check-circle"
                  d="M24,16a8,8,0,1,1-8-8A8,8,0,0,1,24,16Zm-8.925,4.236L21.01,14.3a.516.516,0,0,0,0-.73l-.73-.73a.516.516,0,0,0-.73,0L14.71,17.681l-2.26-2.26a.516.516,0,0,0-.73,0l-.73.73a.516.516,0,0,0,0,.73l3.355,3.355a.516.516,0,0,0,.73,0Z"
                  transform="translate(-8 -8)" fill="#8a8f9c"/>
        </svg>

    )
};

export const EditIcon = () => {
    return (
        <svg width="14.649" height="14.649" viewBox="0 0 14.649 14.649">
            <path id="user-edit" d="M21.251,23.947l-.346,3.1a.81.81,0,0,0,.9.9l3.1-.346,7.015-7.015-3.647-3.647Zm13.943-8.363-1.928-1.928a1.219,1.219,0,0,0-1.719,0l-2.126,2.126,3.652,3.647L35.2,17.3a1.224,1.224,0,0,0-.005-1.719Z" transform="translate(-20.901 -13.302)" fill="#fff"/>
        </svg>
    )
};

export const CustomDetailsIcon = () => {
    return (
        <svg width="38" height="30.401" viewBox="0 0 38 30.401">
            <path id="user-edit"
                  d="M21.31,25.728l-.4,3.622a.945.945,0,0,0,1.045,1.045l3.616-.4L33.755,21.8,29.5,17.546Zm16.275-9.761-2.25-2.25a1.423,1.423,0,0,0-2.007,0L30.846,16.2l4.263,4.257,2.482-2.482a1.428,1.428,0,0,0-.006-2.007ZM13.294,17.1A8.547,8.547,0,0,0,21.85,8.544,8.643,8.643,0,0,0,13.306,0,8.547,8.547,0,0,0,4.75,8.556,8.643,8.643,0,0,0,13.294,17.1ZM13.033,2.856A5.7,5.7,0,1,1,7.606,8.283a5.7,5.7,0,0,1,5.427-5.427ZM7.98,20.9c.867,0,2.274.95,5.32.95,3.07,0,4.447-.95,5.32-.95a5.084,5.084,0,0,1,2.7.79l2.043-2.043a7.9,7.9,0,0,0-4.744-1.6c-1.7,0-2.523.95-5.32.95s-3.61-.95-5.32-.95A7.982,7.982,0,0,0,0,26.03v1.52A2.851,2.851,0,0,0,2.85,30.4H18.187a6.644,6.644,0,0,1,.053-2.85H2.85V26.03A5.139,5.139,0,0,1,7.98,20.9Z"
                  transform="translate(0 0)" fill="#8a8f9c"/>
        </svg>

    )
};

export const VehicleDetailsIcon = () => {
    return (
        <svg width="49.495" height="34.208" viewBox="0 0 49.495 34.208">
            <defs>
                <clipPath id="clip-path">
                    <path id="path"
                          d="M36.775,36.089v2.06a2.06,2.06,0,1,1-4.119,0v-2.06h-15.1v2.06a2.06,2.06,0,1,1-4.119,0v-2.06A3.443,3.443,0,0,1,10,32.656V24.418a3.408,3.408,0,0,1,1.545-2.849l2.128-8.513A4.142,4.142,0,0,1,17.552,10h15.1a4.142,4.142,0,0,1,3.879,3.055l2.128,8.513a3.408,3.408,0,0,1,1.545,2.849v8.239a3.443,3.443,0,0,1-3.433,3.433ZM33.892,13.707a1.451,1.451,0,0,0-1.236-.961h-15.1a1.451,1.451,0,0,0-1.236.961L14.5,20.985H35.711l-1.819-7.278ZM12.746,24.418v8.239a.689.689,0,0,0,.687.687H36.775a.689.689,0,0,0,.687-.687V24.418a.689.689,0,0,0-.687-.687H13.433a.689.689,0,0,0-.687.687ZM17.552,30.6a2.06,2.06,0,1,1,2.06-2.06,2.066,2.066,0,0,1-2.06,2.06Zm15.1,0a2.06,2.06,0,1,1,2.06-2.06,2.066,2.066,0,0,1-2.06,2.06Z"
                          transform="translate(-10 -10)" fill="#8a8f9c"/>
                </clipPath>
            </defs>
            <g id="Group_2637" data-name="Group 2637" transform="translate(-339.505 -393.81)">
                <path id="user-edit"
                      d="M21.31,25.728l-.4,3.622a.945.945,0,0,0,1.045,1.045l3.616-.4L33.755,21.8,29.5,17.546Zm16.275-9.761-2.25-2.25a1.423,1.423,0,0,0-2.007,0L30.846,16.2l4.263,4.257,2.482-2.482a1.428,1.428,0,0,0-.006-2.007ZM4.75,8.556C4.756,13.205,4.744,3.83,4.75,8.556Z"
                      transform="translate(351 394)" fill="#8a8f9c"/>
                <g id="car_icon" data-name="car icon" transform="translate(339.505 393.81)">
                    <rect id="bg" width="34.208" height="34.208" fill="none"/>
                    <g id="icon" transform="translate(2 2)" clipPath="url(#clip-path)">
                        <rect id="bg-2" data-name="bg" width="37.074" height="37.074"
                              transform="translate(-3.433 -3.433)" fill="#8a8f9c"/>
                    </g>
                </g>
            </g>
        </svg>


    )
};

export const InspectionDetailsIcon = () => {
    return (
        <svg id="Group_2638" data-name="Group 2638" width="51" height="35" viewBox="0 0 51 35">
            <path id="user-edit"
                  d="M21.31,25.728l-.4,3.622a.945.945,0,0,0,1.045,1.045l3.616-.4L33.755,21.8,29.5,17.546Zm16.275-9.761-2.25-2.25a1.423,1.423,0,0,0-2.007,0L30.846,16.2l4.263,4.257,2.482-2.482a1.428,1.428,0,0,0-.006-2.007ZM4.75,8.556C4.756,13.205,4.744,3.83,4.75,8.556Z"
                  transform="translate(13 1)" fill="#8a8f9c"/>
            <g id="icon">
                <rect id="bg" width="35" height="35" fill="none"/>
                <path id="path"
                      d="M27.364,4.818h2.109A3.527,3.527,0,0,1,33,8.346V29.472A3.526,3.526,0,0,1,29.473,33H5.527A3.527,3.527,0,0,1,2,29.472V8.346A3.526,3.526,0,0,1,5.527,4.818H7.636V3.409a1.409,1.409,0,1,1,2.818,0V4.818h2.818V3.409a1.409,1.409,0,1,1,2.818,0V4.818h2.818V3.409a1.409,1.409,0,1,1,2.818,0V4.818h2.818V3.409a1.409,1.409,0,1,1,2.818,0V4.818Zm0,2.818V9.045a1.409,1.409,0,0,1-2.818,0V7.636H21.727V9.045a1.409,1.409,0,0,1-2.818,0V7.636H16.091V9.045a1.409,1.409,0,0,1-2.818,0V7.636H10.455V9.045a1.409,1.409,0,0,1-2.818,0V7.636H5.527a.708.708,0,0,0-.709.71V29.472a.709.709,0,0,0,.709.71H29.473a.708.708,0,0,0,.709-.71V8.346a.709.709,0,0,0-.709-.71ZM9.045,16.8a1.409,1.409,0,1,1,0-2.818h14.8a1.409,1.409,0,1,1,0,2.818Zm0,4.932a1.409,1.409,0,1,1,0-2.818H25.955a1.409,1.409,0,1,1,0,2.818Zm0,4.932a1.409,1.409,0,0,1,0-2.818h9.864a1.409,1.409,0,0,1,0,2.818Z"
                      fill="#8a8f9c"/>
            </g>
        </svg>
    )
};

export const AdminDetailsIcon = () => {
    return(
        <svg width="37.181" height="29.748" viewBox="0 0 37.181 29.748">
            <path id="user-cog" d="M19.772,26.959H2.789V25.472a5.029,5.029,0,0,1,5.02-5.02c.848,0,2.225.93,5.206.93a14.408,14.408,0,0,0,4.654-.837,10.656,10.656,0,0,1,.354-2.859c-1.662.081-2.376.906-5.008.906-2.737,0-3.533-.93-5.206-.93A7.811,7.811,0,0,0,0,25.472v1.487a2.79,2.79,0,0,0,2.789,2.789H22.66A11.174,11.174,0,0,1,19.772,26.959ZM13.015,16.733A8.367,8.367,0,1,0,4.648,8.367,8.369,8.369,0,0,0,13.015,16.733Zm0-13.944A5.578,5.578,0,1,1,7.437,8.367,5.587,5.587,0,0,1,13.015,2.789Zm22.456,18.9a6.816,6.816,0,0,0,0-2.475l1.5-.866a.421.421,0,0,0,.192-.494,8.719,8.719,0,0,0-1.929-3.335.424.424,0,0,0-.523-.081l-1.5.866a6.821,6.821,0,0,0-2.144-1.238V12.335a.423.423,0,0,0-.331-.413,8.766,8.766,0,0,0-3.846,0,.423.423,0,0,0-.331.413v1.731A6.821,6.821,0,0,0,24.414,15.3l-1.5-.866a.425.425,0,0,0-.523.081,8.719,8.719,0,0,0-1.929,3.335.428.428,0,0,0,.192.494l1.5.866a6.816,6.816,0,0,0,0,2.475l-1.5.866a.421.421,0,0,0-.192.494,8.763,8.763,0,0,0,1.929,3.335.424.424,0,0,0,.523.081l1.5-.866a6.821,6.821,0,0,0,2.144,1.238v1.731a.423.423,0,0,0,.331.413,8.766,8.766,0,0,0,3.846,0,.423.423,0,0,0,.331-.413V26.837A6.821,6.821,0,0,0,33.211,25.6l1.5.866a.425.425,0,0,0,.523-.081,8.719,8.719,0,0,0,1.929-3.335.428.428,0,0,0-.192-.494Zm-6.653,1.58a2.818,2.818,0,1,1,2.818-2.818A2.821,2.821,0,0,1,28.818,23.27Z" fill="#8a8f9c"/>
        </svg>
    )
};

export const DownloadPhoto = () => {
    return (
        <svg width="20" height="17.829" viewBox="0 0 20 17.829">
            <g id="Group_2615" data-name="Group 2615" transform="translate(0 0)">
                <g id="icon">
                    <path id="Path_742" data-name="Path 742"
                          d="M25,15.286a.774.774,0,0,0-1.538,0v5.486a.69.69,0,0,1-.769.686H7.308a.69.69,0,0,1-.769-.686V9.8a.69.69,0,0,1,.769-.686h3.538s.154,0,.154-.137a.14.14,0,0,1,.154-.137V8.7l1.231-2.331h5.231L19,8.7h0c0,.137.154.137.154.274h.154c0,.137.154.137.308.137h3.077a.69.69,0,0,1,.769.686A.774.774,0,0,0,25,9.8a2.249,2.249,0,0,0-2.308-2.057H20.077L18.692,5.411c0-.274-.308-.411-.615-.411H11.923c-.308,0-.615.137-.615.411L9.923,7.743H7.308A2.249,2.249,0,0,0,5,9.8V20.771a2.249,2.249,0,0,0,2.308,2.057H22.692A2.249,2.249,0,0,0,25,20.771Z"
                          transform="translate(-5 -5)" fill="#fff"/>
                    <path id="Path_743" data-name="Path 743"
                          d="M12.614,9.5A4.041,4.041,0,0,0,8.5,13.614a4.041,4.041,0,0,0,4.114,4.114,4.041,4.041,0,0,0,4.114-4.114A4.041,4.041,0,0,0,12.614,9.5Zm0,6.857a2.743,2.743,0,1,1,2.743-2.743A2.751,2.751,0,0,1,12.614,16.357Z"
                          transform="translate(-3.7 -3.329)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
};

export const BookInspectionIcon = () => {
    return (
        <svg width="18" height="12" viewBox="0 0 18 12">
            <path id="inbox"
                  d="M17.731,69.364l-3.284-4.72A1.5,1.5,0,0,0,13.216,64H4.784a1.5,1.5,0,0,0-1.231.643L.269,69.364A1.5,1.5,0,0,0,0,70.22V74.5A1.5,1.5,0,0,0,1.5,76h15A1.5,1.5,0,0,0,18,74.5V70.22A1.5,1.5,0,0,0,17.731,69.364ZM4.784,65.5h8.432L15.651,69H11.823l-1,2H7.177l-1-2H2.349Zm11.716,9H1.5v-4H5.25l1,2h5.5l1-2H16.5Z"
                  transform="translate(0 -64)" fill="#fff"/>
        </svg>

    )
};

export const ContactUsIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16">
            <path id="life-ring"
                  d="M16,24a8,8,0,1,0-8-8A8,8,0,0,0,16,24Zm-3.335-2.475L14.388,19.8a4.14,4.14,0,0,0,3.225,0l1.723,1.723a6.464,6.464,0,0,1-6.671,0ZM18.581,16A2.581,2.581,0,1,1,16,13.419,2.584,2.584,0,0,1,18.581,16Zm2.945,3.335L19.8,17.612a4.14,4.14,0,0,0,0-3.225l1.723-1.723A6.464,6.464,0,0,1,21.525,19.335Zm-2.19-8.861L17.612,12.2a4.14,4.14,0,0,0-3.225,0l-1.723-1.723A6.464,6.464,0,0,1,19.335,10.475Zm-8.861,2.19L12.2,14.388a4.14,4.14,0,0,0,0,3.225l-1.723,1.723A6.464,6.464,0,0,1,10.475,12.665Z"
                  transform="translate(-8 -8)" fill="#8a8f9c"/>
        </svg>
    )
};

export const FaqIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14">
            <path id="external-link-square"
                  d="M12.5,32H1.5A1.5,1.5,0,0,0,0,33.5v11A1.5,1.5,0,0,0,1.5,46h11A1.5,1.5,0,0,0,14,44.5v-11A1.5,1.5,0,0,0,12.5,32Zm-.187,12.5H1.688a.187.187,0,0,1-.187-.187V33.688a.187.187,0,0,1,.187-.187H12.313a.187.187,0,0,1,.188.188V44.313a.187.187,0,0,1-.187.188ZM10.625,35l-4.25,0A.375.375,0,0,0,6,35.38v.867a.375.375,0,0,0,.383.375L8.2,36.555,3.11,41.64a.375.375,0,0,0,0,.53l.72.72a.375.375,0,0,0,.53,0L9.445,37.8l-.067,1.813A.375.375,0,0,0,9.753,40h.867A.375.375,0,0,0,11,39.625l0-4.25A.375.375,0,0,0,10.625,35Z"
                  transform="translate(0 -32)" fill="#8a8f9c"/>
        </svg>
    )
};

export const SettingsIcon = () => {
    return (
        <svg width="17.498" height="14" viewBox="0 0 17.498 14">
            <path id="user-cog"
                  d="M9.305,12.688H1.313v-.7A2.367,2.367,0,0,1,3.675,9.625a10.706,10.706,0,0,0,2.45.438,6.781,6.781,0,0,0,2.19-.394,5.015,5.015,0,0,1,.167-1.345,15.788,15.788,0,0,1-2.357.427c-1.288,0-1.662-.437-2.45-.437A3.676,3.676,0,0,0,0,11.988v.7A1.313,1.313,0,0,0,1.313,14h9.352A5.259,5.259,0,0,1,9.305,12.688ZM6.125,7.875A3.938,3.938,0,1,0,2.188,3.938,3.939,3.939,0,0,0,6.125,7.875Zm0-6.562A2.625,2.625,0,1,1,3.5,3.938,2.629,2.629,0,0,1,6.125,1.313Zm10.568,8.895a5.985,5.985,0,0,0,0-1.165l.705-.407a.2.2,0,0,0,.09-.232,4.1,4.1,0,0,0-.908-1.57.2.2,0,0,0-.246-.038L15.63,7.2a3.21,3.21,0,0,0-1.009-.582V5.805a.2.2,0,0,0-.156-.194,4.126,4.126,0,0,0-1.81,0,.2.2,0,0,0-.156.194V6.62A3.21,3.21,0,0,0,11.49,7.2l-.705-.407a.2.2,0,0,0-.246.038A4.1,4.1,0,0,0,9.63,8.4a.2.2,0,0,0,.09.232l.705.407a3.208,3.208,0,0,0,0,1.165l-.705.407a.2.2,0,0,0-.09.232,4.124,4.124,0,0,0,.908,1.57.2.2,0,0,0,.246.038l.705-.407a3.21,3.21,0,0,0,1.009.582v.815a.2.2,0,0,0,.156.194,4.126,4.126,0,0,0,1.81,0,.2.2,0,0,0,.156-.194V12.63a6.006,6.006,0,0,0,1.009-.582l.705.407c.079.046.246-.038.246-.038a7.523,7.523,0,0,0,.908-1.57.2.2,0,0,0-.09-.232Zm-3.131.744a1.326,1.326,0,1,1,1.326-1.326A1.327,1.327,0,0,1,13.563,10.951Z"
                  fill="#8a8f9c"/>
        </svg>
    )
};


export const FlagIcon = () => {
    return (
        <svg width="17.498" height="14" viewBox="0 0 60 60">
            <path d="M51.371,3.146c-0.203-0.081-5.06-1.997-10.815-1.997c-3.434,0-6.47,0.687-9.024,2.042C29.269,4.392,26.199,5,22.407,5
  C17.099,5,11.865,3.788,10,3.307V1c0-0.553-0.447-1-1-1S8,0.447,8,1v3c0,0.014,0.007,0.026,0.008,0.04C8.008,4.052,8,4.062,8,4.074
  V33v1.074V59c0,0.553,0.447,1,1,1s1-0.447,1-1V35.373C12.273,35.937,17.243,37,22.407,37c4.122,0,7.507-0.688,10.062-2.042
  c2.263-1.201,4.983-1.81,8.087-1.81c5.357,0,10.027,1.836,10.073,1.854c0.309,0.124,0.657,0.086,0.932-0.102
  C51.835,34.716,52,34.406,52,34.074v-30C52,3.665,51.751,3.298,51.371,3.146z"/>

        </svg>

    )
};

export const PdfIcon = () => {
    return (<svg width="30" height="20" viewBox="0 0 60 60">
    <g>
    <path style={{fill: 'white'}}  d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
       c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"/>
    <polygon points="37.5,0.151 37.5,12 49.349,12 	"/>
    <path style={{fill: 'currentColor'}} d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
       c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
       c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
       c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
       c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
       c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
       c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
       c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
        M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
       c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
       c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
        M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"/>
    <path d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"/>
    <g style={{fill: 'white'}}>
       <path d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
           c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
           c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
           s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
           c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
           c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
           c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"/>
       <path d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
           s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
           c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
            M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
           c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"/>
       <path d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"/>
       </g>
       </g>
    </svg>)
}
import React from 'react';
import './StepsControl.css'

const StepsControl = ({step, onStepClick}) => {
    return (
        <div className="tutorial">
            <div className="tutorial-line"/>
            <div className="tutorial-items">
                <div className="tutorial-item">
                    <div className={`circle ${step === 1 && 'active'}`} onClick={() => onStepClick(1)}>1</div>
                    <div className="tutorial-caption">Step 1</div>
                </div>
                <div className="tutorial-item">
                    <div className={`circle ${step === 2 && 'active'}`} onClick={() => onStepClick(2)}>2</div>
                    <div className="tutorial-caption">Step 2</div>
                </div>
                <div className="tutorial-item">
                    <div className={`circle ${step === 3 && 'active'}`} onClick={() => onStepClick(3)}>3</div>
                    <div className="tutorial-caption">Step 3</div>
                </div>
            </div>
        </div>
    )
};

export default StepsControl
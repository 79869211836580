import React, { Component } from 'react';
import { Input } from "reactstrap";
import MaterialIcon from "material-icons-react";
import ConfirmationModal from "../../elements/modal/ConfirmModal";
import FaultCodesTable from "../FaultCodesTable/FaultCodesTable";
import { isCategoryNameValid } from '../../../static/utils';

class ReportRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editReport: false,
            deleteModal: false,
            dto: {
                name: '',
            },
        }
    }

    componentDidMount() {
        this.setReport()
    }

    onCancelEdit = () => {
        this.setReport();
        const { editReport } = this.state;
        this.setState({ editReport: !editReport });
    };

    setReport = () => {
        const { dto } = this.state;
        const { item } = this.props;
        dto.name = item ? item : '';
        this.setState({ dto });
    };

    deleteModalToggle = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    };

    onInputChange = event => {
        const { dto } = this.state;
        const {hasError} = this.props;
        dto[event.target.name] = event.target.value;
        const nameIsValid = isCategoryNameValid(event.target.value);
        hasError(nameIsValid);
        this.setState({ dto });
    };


    deleteItem = () => {
        const { index, deleteItem, item } = this.props;
        deleteItem(item, index, this.deleteModalToggle);
    };


    editReport = () => {
        const { index, editItem, item } = this.props;
        const { dto } = this.state;
        editItem(dto, item, index);
        this.setState({ editReport: false });
    };

    onEnterPressAdd = (event) => {
        const { dto } = this.state;
        if (event.key === 'Enter' && dto.name) {
            this.editReport()
        }
    };

    render() {
        const { dto, editReport, deleteModal } = this.state;
        const { item, issues, showFaultyCodes, showIcon, fromBasic, activeReportId } = this.props;
        const isButtonDisabled = isCategoryNameValid(dto.name);
        return (
            <React.Fragment>
                <tr>
                    <td className="v-middle"> <span className="d-flex align-items-center">
                        {activeReportId !== 'Wheels and Tyres' && <React.Fragment>{showIcon && <MaterialIcon icon="remove_circle_outline" onClick={() => showFaultyCodes(item)} />}{!showIcon && <MaterialIcon icon="add_circle_outline" onClick={() => showFaultyCodes(item)} />}</React.Fragment>}
                        {editReport
                            ? <Input name={'name'} placeholder={'Field'} value={dto.name}
                            onChange={event => this.onInputChange(event)}
                            onKeyPress={event => this.onEnterPressAdd(event)}
                        />
                            : <span className="ml-2">{item}</span>}
                    </span>
                    </td>
                    <td>
                        {editReport
                            ? <div className='d-flex'>

                                <button className='option-button'
                                    onClick={() => this.editReport()} disabled={!isButtonDisabled}>
                                    <i className="material-icons">save</i>
                                </button>

                                <button className='option-button'
                                    onClick={() => {
                                        this.onCancelEdit();
                                    }}>
                                    <i className="material-icons">reply</i>
                                </button>

                            </div>
                            : <div className='d-flex'>

                                <button className='option-button'
                                    onClick={() => {
                                        this.setReport();
                                        this.setState({ editReport: !editReport });
                                    }}
                                >
                                    <MaterialIcon icon="create" />
                                </button>

                                <button className='option-button'
                                    onClick={() => this.deleteModalToggle()}
                                >
                                    <MaterialIcon icon="delete" />
                                </button>

                                <ConfirmationModal toggle={this.deleteModalToggle}
                                    onAccept={this.deleteItem}
                                    modal={deleteModal}
                                    header={'Are you sure you want to delete this item?'}
                                    body={''}
                                    showButton={true}
                                />
                            </div>}
                    </td>
                </tr>

            </React.Fragment>
        )
    }
}

export default ReportRow
import app from "@firebase/app";
import {
  convertDateToFirestoreTimestamp,
  executeSearchQuery
} from "./firestoreUtils";
import { setEndDay, setStartDay } from "../../static/utils";

const getEvaluationCollection = () => app.firestore().collection("evaluations");

export const getEvaluations = (
  pageSize,
  lastElement,
  type,
  search,
  dateRange
) => {
  const query = buildSearchQuery(
    pageSize,
    lastElement,
    type,
    search,
    dateRange
  );
  return executeSearchQuery(query);
};

export const getInspectionId = () => {
  return new Promise(function(resolve, reject) {
    const docRef = getEvaluationCollection().doc();
    resolve(docRef.id);
  });
};

export const createInspection = (dto, bookingId) => {
  return new Promise(function(resolve, reject) {
    dto.inspectionRef = bookingId.substr(0, 6).toUpperCase();
    const docRef = getEvaluationCollection().doc(bookingId);
    docRef.set({ ...dto });
    if (docRef && docRef.id) {
      resolve(docRef.id);
    } else {
      reject("Object not created");
    }
  });
};

export const updateInspection = (dto, uid) => {
  return new Promise(function(resolve, reject) {
    const docRef = getEvaluationCollection().doc(uid);
    docRef.update({ ...dto });
    if (docRef && docRef.id) {
      resolve(docRef.id);
    } else {
      reject("Object not created");
    }
  });
};

export const getInspectionsByTimeRange = (forTime, toTime) => {
  const query = buildTimeQuery(forTime, toTime);
  return executeSearchQuery(query);
};

export const getInspectionsByTime = time => {
  const query = buildTimeQuery(null, null, time);
  return executeSearchQuery(query);
};

const buildTimeQuery = (forTime, toTime, time) => {
  let query = getEvaluationCollection();
  if (forTime) {
    query = query.where("bookingDate", ">=", forTime);
  }
  if (toTime) {
    query = query.where("bookingDate", "<=", toTime);
  }
  if (time) {
    query = query.where("bookingDate", "==", time);
  }
  return query;
};

const buildSearchQuery = (pageSize, lastElement, type, search, dateRange) => {
  let query = getEvaluationCollection();
  // if (type) {
  //   query = query.where("inspectionType", "==", type);
  // }
  // if (search && search.name) {
  //   const cName = search.name;
  //   query = query.where("cName", "==", cName);
  // }
  // if (search && search.phoneCode) {
  //   query = query.where("cPhCode", "==", search.phoneCode);
  // }
  // if (search && search.phone) {
  //   query = query.where("cPhNumber", "==", search.phone);
  // }
  // if (search && search.plate) {
  //   query = query.where("vPlate", "==", search.plate);
  // }
  // if (search && search.vin) {
  //   query = query.where("vVIN", "==", search.vin);
  // }
  // if (search && search.reference) {
  //   query = query.where("inspectionRef", "==", search.reference);
  // }
  // if (search && search.date) {
  //   const forDate = setStartDay(search.date);
  //   query = query.where(
  //     "bookingDate",
  //     ">",
  //     convertDateToFirestoreTimestamp(forDate)
  //   );
  // }
  // if (search && search.date) {
  //   const toDate = setEndDay(search.date);
  //   query = query.where(
  //     "bookingDate",
  //     "<",
  //     convertDateToFirestoreTimestamp(toDate)
  //   );
  // }

  if (dateRange && dateRange.fromDate) {
    query = query.where(
      "eDate",
      ">",
      convertDateToFirestoreTimestamp(dateRange.fromDate)
    );
  }

  if (dateRange && dateRange.toDate) {
    query = query.where(
      "eDate",
      "<",
      convertDateToFirestoreTimestamp(dateRange.toDate)
    );
  }

  if (pageSize) {
    query = query.limit(pageSize);
  }
  if (lastElement) {
    query = query.startAfter(lastElement);
  }
  return query;
};
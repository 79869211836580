import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import App from "./pages/App";
import Firebase, { FirebaseContext } from "./firebase";
import {Router} from 'react-router-dom';
import history  from './history';

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <Router history={history}>
            <App />
        </Router>
    </FirebaseContext.Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

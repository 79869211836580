import React, {Component} from 'react';
import MaterialIcon from "material-icons-react";
import ConfirmationModal from "../../elements/modal/ConfirmModal";
import {Input} from "reactstrap";
import './BankTable.css';
class BankRow extends Component {
  constructor(props) {
      super(props);
      this.state = {
          editBank: false,
          showResetModal: false,
          showDeleteModal: false,
          showErrorModal: false,
          dto: {
            uid: '',
            emails: '',
            emailHeadOffice:''
          },
      }
  }


  componentDidMount() {
    this.setUser();
  }


  onCancelEdit = () => {
    this.setUser();
    const {editBank} = this.state;
    this.setState({editBank: !editBank});
  };

  editBank = () => {
    const {index, editBank, bank} = this.props;
    const {dto} = this.state;
   
    editBank(dto, bank.uid, bank.email, index);
    this.setState({editBank: false});
  };

  setUser = () => {
    const {dto} = this.state;
    const {bank} = this.props;
    dto.uid = bank.uid ? bank.uid : '';
    dto.emails = bank.emails ? bank.emails : '';
    dto.emailHeadOffice = bank.emailHeadOffice ? bank.emailHeadOffice :'';
    this.setState({dto});
  };

  onInputChange = (event,index) => {
    const {dto} = this.state;
    if(index !== undefined){
      dto.emails[index]=event.target.value;
    } else {
      dto[event.target.name] = event.target.value;

    }
    this.setState({dto});
  };

  onEnterPressAdd = (event) => {
    const {dto} = this.state;
    if (event.key === 'Enter' && dto.uid && dto.email) {
        this.editBank()
    }
  };

  deleteModalToggle = () => {
    this.setState(prevState => ({
        showDeleteModal: !prevState.showDeleteModal
    }));
  };

  errorModalToggle = () => {
    this.setState(prevState => ({
        showErrorModal: !prevState.showErrorModal
    }));
  };

  deleteBank = () => {
    const {bank, deleteBank} = this.props;
    const {uid} = bank;
    deleteBank(uid).then(() => {
      this.deleteModalToggle();
    }).catch(err => {
      this.handleErrorModal()
    })
  }

  onAddMoreEmail = () => {
    const {dto} = this.state;
    let allEmails = dto.emails;
    allEmails.push('')
    let newObj = {
      ...dto,
      emails:allEmails
    }
    this.setState({
      dto: newObj
    })

  }

  onDeleteEditEmail = (index) =>{
    const {dto} = this.state;
    let dtoObj = dto;
    dtoObj.emails.splice(index,1)
    this.setState({
      dto: dtoObj
    })
  }
  

  render() {
    const {editBank, dto, showDeleteModal, showErrorModal} = this.state;
    const {bank} = this.props;
    const isButtonDisabled = dto.uid;
    let allEmails = ''
    if(!editBank){
      allEmails = bank.emails.join(", ");
    }
    console.log("allEmailsallEmails",allEmails)
    return (

      <tr>
        <td>
          {editBank ? (
            <Input 
              name='uid' 
              placeholder={'Name'} 
              value={dto.uid}
              onChange={event => this.onInputChange(event)}
              onKeyPress={event => this.onEnterPressAdd(event)}
            />
            ) : (
                bank.uid
          )}
        </td>
        <td>
          {editBank &&   <div className = 'mb-1'>
            <label className='mb-0'>
              Head Office:
            </label>
<Input 
              name={'emailHeadOffice'}
              placeholder={'Email'} 
              value={dto.emailHeadOffice}
              onChange={event => this.onInputChange(event)}
              onKeyPress={event => this.onEnterPressAdd(event)}
            />
            {/* <button onClick = {() => this.onDeleteEditEmail(index)} className="border-0 p-0 bg-transparent" ><i class="material-icons">close</i></button> */}
           
            </div>} 
             {!editBank && dto.emailHeadOffice && <div>
             Head Office: {dto.emailHeadOffice}
           </div>}
            
          {editBank && <label className='mb-0'>
                Email Rep Bank:
            </label> }
          {editBank ? (
            dto.emails.map((email,index) => {
              return(
               
<div>
  <div className="d-flex align-content-center mb-1">
<Input 
              name={`email${index}`}
              placeholder={'Email'} 
              value={email}
              onChange={event => this.onInputChange(event,index)}
              onKeyPress={event => this.onEnterPressAdd(event)}
            /><button onClick = {() => this.onDeleteEditEmail(index)} className="border-0 p-0 bg-transparent" ><i class="material-icons">close</i></button>
           
           </div>
            
</div>
              )

            })
            
            ) : (
              allEmails.length > 0 && <div>
<label className='mb-0'>
                Emails : 
            </label>
            {allEmails}
            </div>)
            }
          {editBank && <button onClick = {() => this.onAddMoreEmail()}className="border-0 p-0 bg-transparent d-flex align-content-center" ><i class="material-icons">add</i>Add more</button>}
        </td>
                <td>
                    {editBank
                        ? <div className='d-flex'>
                            <button className='option-button'
                                    onClick={() => this.editBank()} disabled={!isButtonDisabled}>
                                <i className="material-icons">how_to_reg</i>
                            </button>

                            <button className='option-button'
                                    onClick={() => {
                                        this.onCancelEdit()
                                    }}>
                                <i className="material-icons">reply</i>
                            </button>

                        </div>
                        : <div className='d-flex'>
                            <button className='option-button'
                                    onClick={() => this.setState({editBank: !editBank})}
                            >
                                <MaterialIcon icon="create"/>
                            </button>
                            {/* {user.email && <button className='option-button'
                                                   onClick={() => this.resetPasswordModalToggle()}
                            >
                                <MaterialIcon icon="sms_failed"/>
                            </button>} */}
                            <button className='option-button'
                                    onClick={this.deleteModalToggle}
                            >
                                <MaterialIcon icon="delete"/>
                            </button>
                            {/* <ConfirmationModal toggle={this.resetPasswordModalToggle}
                                               onAccept={this.resetPassword}
                                               modal={showResetModal}
                                               header={'Reset password'}
                                               body={`Are you sure you want to reset ${user.email} password?`}
                                               showButton={true}
                            /> */}
                            <ConfirmationModal toggle={this.deleteModalToggle}
                                               onAccept={this.deleteBank}
                                               modal={showDeleteModal}
                                               header={'Delete Bank'}
                                               body={`Are you sure you want to delete ${bank.uid}?`}
                                               showButton={true}
                            />
                             <ConfirmationModal toggle={this.errorModalToggle}
                                               modal={showErrorModal}
                                               header={'Error'}
                                               body={"Intenal server error. Please try again"}
                                               showButton={false}
                            />
                        </div>}
                </td>
            </tr>
    );
  }
}
export default BankRow;
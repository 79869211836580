import React, { Component } from "react";
import { compose } from "recompose";
import { withAuthorization } from "../../../session/index";
import ConfirmationModal from "../../elements/modal/ConfirmModal";
import { SendIcon, PdfIcon } from "../../elements/Icons/Icons";
import { FormGroup, Label, Button } from "reactstrap";
import Input from "reactstrap/es/Input";
import { isEmailValid } from "../../../static/utils";
import { sendEmail } from "../../../api/vehicle";
import CircularLoader from "../../elements/Loader/CircularLoader";
import { Link } from 'react-router-dom';

class SendPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendEmailModal: false,
      emailAddress: "",
      showErrorModal: false,
      showSuccessModal: false
    };
  }

  sendEmailModalToggle = () => {
    if (this.props) {
      const evaluation = this.props.agGridReact.props.rowData[
        this.props.node.rowIndex
      ];
      const emailTextFieldValue = evaluation.customerEmailAddress
        ? evaluation.customerEmailAddress
        : "";
      this.setState(prevState => ({
        showErrorModal: false,
        sendEmailModal: !prevState.sendEmailModal,
        emailAddress: emailTextFieldValue
      }));
    }

    // this.props.context.componentParent.methodFromParent(`Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`)
  };

  isAllEmailsValid = () => {
    const { emailAddress } = this.state;
    const emailIds = emailAddress.replace(/(^\s*,)|(,\s*$)/g, "");
    const allEmails = emailIds.split(",");
    for (let i = 0; i < allEmails.length; i++) {
      const isValidEmail = isEmailValid(allEmails[i].trim());
      if (!isValidEmail) {
        return false;
      }
    }
    return true;
  };

  renderModalBody = () => {
    const evaluation = this.props.agGridReact.props.rowData[
      this.props.node.rowIndex
    ];
    const { customerEmailAddress, bankNameRep, pdfURLShort } = evaluation;
    const {
      sendEmailModal,
      emailAddress,
      showSuccessModal,
      showErrorModal
    } = this.state;
    const isValidEmail = this.isAllEmailsValid(emailAddress);
    return (
      
      <React.Fragment>
       
        <FormGroup className="createFormGroup d-flex flex-row createFormGroup ">
          <Label for="chooseLocation">
            <b>Email Address</b>
          </Label>
          <Input
            type="text"
            name="emailAddress"
            id="emailAddress"
            value={emailAddress}
            onChange={event => this.onInputChange(event)}
          />
        </FormGroup>
        <FormGroup className="createFormGroup d-flex flex-row createFormGroup ">
          <Label for="chooseLocation">
            <i>* Multiple addresses can input separate by commas.</i>
          </Label>
        </FormGroup>
        {bankNameRep &&
          customerEmailAddress &&
          bankNameRep[0].toLowerCase() !== customerEmailAddress.toLowerCase() && (
            <FormGroup className="createFormGroup d-flex flex-row createFormGroup ">
              <Label for="chooseLocation" />
            </FormGroup>
          )}
        {emailAddress && !isValidEmail && (
          <span
            className="error"
            style={{
              marginLeft: "10px",
              fontSize: 13
            }}
          >
            Email address is Invalid
          </span>
        )}
      </React.Fragment>
    );
  };

  sendEmail = () => {
    const { emailAddress } = this.state;
    const evaluation = this.props.agGridReact.props.rowData[
      this.props.node.rowIndex
    ];

    const isValidEmail = this.isAllEmailsValid(emailAddress);
    if (isValidEmail) {
      const emailId = emailAddress.replace(/(^\s*,)|(,\s*$)/g, "");
      let id = "";
      if (typeof evaluation.uid === "undefined") {
        id = evaluation.objectID;
      } else {
        id = evaluation.uid;
      }
      this.setState({
        showLoader: true
      });
      sendEmail({ inspectionId: id, emailId })
        .then(response => {
          if (response) {
            this.setState(
              {
                sendEmailModal: false,
                emailAddress: "",
                showLoader: false
              },
              () => {
                this.successModalToggle();
              }
            );
          } else {
            this.setState(
              {
                sendEmailModal: false,
                showLoader: false
              },
              () => {
                this.errorModalToggle();
              }
            );
          }
        })
        .catch(err => {
          this.setState(
            {
              sendEmailModal: false,
              showLoader: false
            },
            () => {
              this.errorModalToggle();
            }
          );
        });
    }
  };

  errorModalToggle = () => {
    this.setState(prevState => ({
      showErrorModal: !prevState.showErrorModal
    }));
  };

  successModalToggle = () => {
    this.setState(prevState => ({
      showSuccessModal: !prevState.showSuccessModal
    }));
  };

  render() {
    const {
      sendEmailModal,
      showErrorModal,
      showSuccessModal,
      showLoader
    } = this.state;
    const evaluation = this.props.agGridReact.props.rowData[
      this.props.node.rowIndex
    ];
    const {  pdfURLShort } = evaluation;
    console.log("pdfURLShort",pdfURLShort)

    const body = this.renderModalBody();
    if (showLoader) {
      return (
        <div>
          <CircularLoader />
        </div>
      );
    }
    return (
     
      <div>
{/*          
        <button
          className="icon-btn"
          style={{ color: "#e00000" }}
          // onClick={() => this.sendEmailModalToggle()}
        > */}
          <a target = "_blank" href= {pdfURLShort} >
          <PdfIcon />
          </a>
         
        {/* </button> */}
        <ConfirmationModal
          toggle={this.sendEmailModalToggle}
          onAccept={this.sendEmail}
          modal={sendEmailModal}
          buttons={[null, <SendIcon />]}
          header={"Resend PDF Report"}
          body={body}
          showButton={true}
        />
        <ConfirmationModal
          toggle={this.successModalToggle}
          modal={showSuccessModal}
          header={"Success"}
          body={`Report sent Successfully`}
          showButton={false}
        />
        <ConfirmationModal
          toggle={this.errorModalToggle}
          modal={showErrorModal}
          header={"Error"}
          body={`Your PDF Report couldn’t be sent. We’re sorry. Please try again later.`}
          showButton={false}
        />
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default SendPdf;

import React, { Component } from 'react';
import './LocationsTable.css';
import {
  createLocation,
  deleteLocation,
  getAllLocation,
  updateLocation
} from '../../../firebase/api/locations';
import Loader from '../../elements/Loader/Loader';
import { Input, Table } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import LocationRow from './LocationRow';
import LocationSearchInput from '../../elements/GooglePlaces/GooglePlaces';
import GoogleMap from '../../elements/GoogleMap/GoogleMap';

class LocationsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addLocation: false,
      showLoader: false,
      dto: {
        name: '',
        latitude: null,
        longitude: null,
        radius: 0.5,
        nickname: null,
      },

      locationType: 'fix',
      locations: []
    };
  }

  componentDidMount() {
    const { locations } = this.state;
    this.setState({ showLoader: true });
    getAllLocation().then(results => {
      if (results) {
        results.forEach(function(doc) {
          const data = doc.data();
          data.uid = doc.ref.id;
          locations.push(data);
        });
        this.setState({ locations, showLoader: false });
      } else {
        this.setState({ locations, showLoader: false });
      }
    });
  }

  resetInputs = () => {
    const { dto } = this.state;
    dto.name = '';
    dto.latitude = null;
    dto.longitude = null;
    dto.radius = 0.5;
		dto.nickname = '';
		
		this.setState({ dto, locationType: 'fix' });
  };

  onInputChange = event => {
    const { dto } = this.state;
    dto[event.target.name] = event.target.value;
    this.setState({ dto });
  };

    onLocationTypeChange = (event) =>{
        this.setState({ locationType: event.target.value});
    }

  onLocationChange = (address, latLng, nickname) => {
    const { dto, locationType } = this.state;
    dto.name = address;
    dto.nickname = nickname;
    if (latLng && locationType === 'fix') {
      dto.latitude = latLng.lat;
      dto.longitude = latLng.lng;
    }
    this.setState({ dto });
  };

  addNewLocation = () => {
		const { dto, locations } = this.state;
    /*
     * If latitude and longitude are null means that the location to add is 'mobile'
     * then the nickname will be use as a name also.
     */
    if(dto.latitude == null && dto.longitude == null){
      dto.name = dto.nickname;
    }
    
    createLocation(dto).then(result => {
      if (result) {
        const data = {
          uid: result,
          ...dto
        };
        locations.push(data);
        this.setState({ locations }, () => this.resetInputs());
      }
    });
	};
	

  deleteLocation = (uid, index, callback) => {
    deleteLocation(uid);
    const { locations } = this.state;
    locations.splice(index, 1);
    this.setState({ locations }, () => callback());
  };

  editLocation = (dto, uid, index) => {
    const { locations } = this.state;
    updateLocation(dto, uid).then(result => {
      if (result) {
        locations[index].name = dto.name;
        locations[index].nickname = dto.nickname;
        this.setState({ locations });
      }
    });
  };

  onEnterPressAdd = event => {
    const { dto } = this.state;
    if (event.key === 'Enter' && dto.name && dto.nickname) {
      this.addNewLocation();
    }
  };

  onFixed = e => {
    console.log(e.target.value);
  };
  render() {
      const { addLocation, dto, locations, showLoader, locationType } = this.state;
    const isButtonDisabled = dto.name || dto.nickname;
    return (
      <div className="locations-table">
        {showLoader ? (
          <Loader />
        ) : (
          <Table bordered>
            <thead>
              <tr>
                <th style={{ width: '70%' }}>Location</th>
                <th style={{ width: '22%' }}>Nickname</th>
                <th style={{ width: '8%' }}>Options</th>
              </tr>
            </thead>
            <tbody>
              {locations &&
                locations.map((location, index) => (
                  <LocationRow
                    key={index}
                    index={index}
                    location={location}
                    deleteLocation={this.deleteLocation}
                    editLocation={this.editLocation}
                  />
                ))}
              <tr>
                {/*<td>{addLocation && <Input name={'name'} placeholder={'Location'} value={dto.name}*/}
                {/*                           onChange={event => this.onInputChange(event)}*/}
                {/*                           onKeyPress={event => this.onEnterPressAdd(event)}*/}
                {/*/>}</td>*/}

                <td>
                  {addLocation && (
                    <div className="locations-table-wrapper">
                      <Input
                      className="inp-select"
                        type="select"
                        name="locationType"
                        value={locationType}
                        onChange={event => this.onLocationTypeChange(event)}
                      >
                        <option value="fix">Fix</option>
                        <option value="mobile">Mobile</option>
                      </Input>
                        <div style={{ marginTop: '10px' }} className={locationType === 'fix' ? '' : "map-hide"}>
                          <GoogleMap lat={dto.latitude} lng={dto.longitude} />
                        </div>
                     
                      <div
                        style={{
                          position: 'relative',
                          zIndex: 99
                        }}
                      >
                        <div style={{ marginTop: '10px' }} className={locationType === 'fix' ? '' : "map-hide"}>
                          <LocationSearchInput
                            location={dto}
                            onLocationChange={this.onLocationChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </td>

                <td>
                  {addLocation && (
											<div className="nickname-wrapper">
                      <Input
                        name={'nickname'}
                        placeholder={'Nickname'}
                        value={dto.nickname}
                        onChange={event => this.onInputChange(event)}
                        onKeyPress={event => this.onEnterPressAdd(event)}
                      />
                    </div>
                  )}
                </td>

                <td>
                  {addLocation ? (
                    <div className="d-flex">
                      <button
                        className="option-button"
                        onClick={() => this.addNewLocation()}
                        disabled={!isButtonDisabled}
                      >
                        <MaterialIcon icon="image" />
                      </button>

                      <button
                        className="option-button"
                        onClick={() => {
                          this.setState({ addLocation: !addLocation }, () =>
                            this.resetInputs()
                          );
                        }}
                      >
                        <MaterialIcon icon="reply" />
                      </button>
                    </div>
                  ) : (
                    <button
                      className="option-button"
                      onClick={() =>
                        this.setState({ addLocation: !addLocation })
                      }
                    >
                      <MaterialIcon icon="add_photo_alternate" />
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}

export default LocationsTable;

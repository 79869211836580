import React, { Component } from "react";
import "./Main.css";
import { compose } from "recompose";
import { Route, Switch, withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { GRID_SIZE } from "../../configs";
import { withAuthorization } from "../../session/index";
import { Button, Container } from "reactstrap";
import { doSignOut } from "../../firebase/api/auth";
import NavBar from "../../components/main/NavBar/NavBar";
import { getEvaluations } from "../../firebase/api/evaluation";
import { BlackKey } from "../../components/elements/Icons/Icons";
import Booking from "../Booking";
import Create from "../Create";
import Footer from "../../components/main/Footer/Footer";
import ConfirmModal from "../../components/elements/modal/ConfirmModal";
import {
  setEndDay,
  setStartDay,
  getLocaleDateString
} from "../../static/utils";
import Admin from "../Admin";
import algoliasearch from "algoliasearch/lite";
import moment from "moment";

function getEvaluationRefFromId(id) {
  if (id) {
    return id.substr(0, 6).toUpperCase();
  }
  return "";
}

const searchClient = algoliasearch(
  "805BWM4PB3",
  "b24341550e1fe4bdfa89fdebbc1d6b25"
);

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAllBooks: true,
      activeBasicBooks: false,
      activeAdvancedBooks: false,
      evaluations: [],
      lastVisibleDocument: null,
      showLeaveModal: false,
      noSearchResults: false,
      search: {
        text: ""
        // name: '',
        // phoneCode: '',
        // phone: '',
        // plate: '',
        // vin: '',
        // date: null,
        // reference: ''
      },
      dateRange: {
        fromDate: setStartDay(new Date()),
        toDate: setEndDay(new Date())
      }
    };
  }

  componentDidMount() {
    const { dateRange, lastVisibleDocument } = this.state;
    this.getEvaluations(lastVisibleDocument, null, true, null, dateRange);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      const { pathname } = this.props.location;
      pathname !== ROUTES.BOOKING &&
        this.setState({
          activeAllBooks: false,
          activeBasicBooks: false,
          activeAdvancedBooks: false
        });
      pathname === ROUTES.BOOKING && this.setState({ activeAllBooks: true });
    }
  }

  handleChange = data => {
    const { search, evaluations } = this.state;
    search.date = data;
    this.setState({ search }, () => {
      this.filterEvaluations(evaluations);
    });
  };

  searchEvaluation = () => {
    const { search } = this.state;
    this.setState({ lastVisibleDocument: null }, () =>
      this.refreshGrid(true, search, null)
    );
  };

  setDateRange = (fromDate, toDate) => {
    const { dateRange } = this.state;
    dateRange.fromDate = setStartDay(fromDate);
    dateRange.toDate = setEndDay(toDate);
    this.setState({ dateRange }, () => this.refreshGrid(true, null, dateRange));
  };

  setActiveTab = (
    { activeAllBooks, activeBasicBooks, activeAdvancedBooks },
    getBooks
  ) => {
    const { pathname } = this.props.location;
    pathname === ROUTES.BOOKING
      ? this.setState(
          {
            activeAllBooks: activeAllBooks || false,
            activeBasicBooks: activeBasicBooks || false,
            activeAdvancedBooks: activeAdvancedBooks || false,
            evaluations: [],
            lastVisibleDocument: null
          },
          () => {
            getBooks();
          }
        )
      : this.showLeaveModalToggle();
  };

  showLeaveModalToggle = () => {
    this.setState(prevState => ({
      showLeaveModal: !prevState.showLeaveModal
    }));
  };

  onLeaveModalAccept = () => {
    this.props.history.push(ROUTES.BOOKING);
    this.showLeaveModalToggle();
  };

  getQueries = () => {
    const { search } = this.state;
    const { name, vin, reference, plate, phone } = search;
    let queries = [];
    const data = {
      eName: name,
      customerFullName: name,
      evaluationRef: reference,
      vPlate: plate,
      vin,
      customerPhone: phone
    };
    queries = Object.keys(data)
      .filter(key => data[key] !== "" && data[key] !== undefined)
      .map(key => ({
        indexName: "evaluations",
        query: data[key],
        params: { restrictSearchableAttributes: [key] }
      }));
    if (queries.length === 0) {
      return [
        {
          indexName: "evaluations",
          query: ""
        }
      ];
    }
    return queries;
  };

  getQueryHits = results => {
    const query = {};
    for (const result of results) {
      if (!!query[result.query]) {
        if (result.hits.length > query[result.query].length) {
          return result.hits;
        }
        return query[result.query];
      } else {
        query[result.query] = result.hits;
      }
    }
    return [];
  };

  concatEvaluations = results => {
    const hits = this.getQueryHits(results);
    if (results && results.length > 0) {
      const obj = {};
      const filteredEvaluations = [];
      results.forEach(result => {
        const arr = hits.length > 0 ? hits : result.hits;
        arr.forEach(hit => {
          obj[hit.evaluationRef] = !!obj[hit.evaluationRef]
            ? Number(obj[hit.evaluationRef]) + 1
            : 1;
          if (obj[hit.evaluationRef] === results.length) {
            filteredEvaluations.push(hit);
          }
        });
      });
      return filteredEvaluations;
    }
    return [];
  };

  getSearchedEvaluations = () => {
    const { evaluations, search } = this.state;
    const { text } = search;
    return evaluations.filter(evaluation => {
      const {
        vYear,
        vMake,
        vModel,
        bankName,
        price,
        eName,
        eDate
      } = evaluation;
      const seconds =
        evaluation && (evaluation.eDate._seconds || evaluation.eDate.seconds);
      const date = getLocaleDateString(seconds);
      const array = [vYear, vMake, vModel, bankName, price, eName, date].map(
        e => e.toString().toLowerCase()
      );
      console.log(array, "array");
      return Boolean(array.find(a => a.includes(text.toLowerCase())));
    });
  };

  onInputChange = event => {
    const { search, evaluations } = this.state;
    search[event.target.name] = event.target.value;
    this.setState({ search });
  };

  refreshGrid = (clearGrid, search, dateRange) => {
    const {
      activeAdvancedBooks,
      activeBasicBooks,
      lastVisibleDocument
    } = this.state;
    this.setState({ noSearchResults: false });
    return activeAdvancedBooks
      ? this.getEvaluations(
          lastVisibleDocument,
          "Advanced",
          clearGrid,
          search,
          dateRange
        )
      : activeBasicBooks
      ? this.getEvaluations(
          lastVisibleDocument,
          "Basic",
          clearGrid,
          search,
          dateRange
        )
      : this.getEvaluations(
          lastVisibleDocument,
          null,
          clearGrid,
          search,
          dateRange
        );
  };

  getEvaluations = (lastElement, type, clearGrid, search, dateRange) => {
    getEvaluations(null, lastElement, type, search, dateRange).then(results => {
      this.populateSearchResult(results, clearGrid);
    });
  };

  updateGrid = callback => {
    const { dateRange } = this.state;
    this.refreshGrid(true, null, dateRange);
    callback();
  };

  populateSearchResult = (results, clearGrid) => {
    const evaluations = clearGrid ? [] : [...this.state.evaluations];
    if (results) {
      let lastVisibleDocument = null;
      results.forEach(function(doc) {
        // lastVisibleDocument = doc;
        const data = doc.data();
        data.uid = doc.ref.id;
        data.evaluationRef = getEvaluationRefFromId(doc.ref.id);
        evaluations.push(data);
      });

      const getFilteredEvaluation = evaluations.filter(
        evaluation => evaluation.status === "Completed"
      );
      this.setState({
        evaluations: getFilteredEvaluation,
        lastVisibleDocument
      });
    } else {
      this.setState({
        evaluations: [],
        lastVisibleDocument: null,
        noSearchResults: true
      });
    }
  };

  signOut = () => {
    doSignOut().then(() => this.props.history.push(ROUTES.LANDING));
  };

  filterEvaluations = filteredData => {
    const { search } = this.state;
    const data = [];
    const { phone, date, phoneCode } = search;
    if (filteredData && filteredData.length > 0) {
      filteredData.forEach(obj => {
        let isContainDate = false;
        let isContainPhone = false;
        if (date && obj && obj.bookingDate && obj.bookingDate._seconds) {
          const bookingDate = moment(new Date(obj.bookingDate._seconds * 1000));
          const filterDate = moment(date);
          isContainDate = filterDate.isSame(bookingDate, "date");
        }

        if (phone && obj.cPhCode && String(obj.cPhCode).includes(phoneCode)) {
          isContainPhone = true;
        }

        if ((!date || isContainDate) && (!phoneCode || isContainPhone)) {
          data.push(obj);
        }
      });
    }
    let state = {
      noSearchResults: true,
      evaluations: []
    };
    if (data.length !== 0) {
      state["evaluations"] = data;
    }
    this.setState(state);
  };

  render() {
    const {
      activeAllBooks,
      activeBasicBooks,
      activeAdvancedBooks,
      evaluations,
      showLeaveModal,
      noSearchResults,
      search,
      dateRange
    } = this.state;
    const searchedEvaluation = this.getSearchedEvaluations();
    return (
      <Container className="p-0" fluid>
        <div className="bodyWrapper">
          <header className={"header"}>
            <div
              className={"headerLogo"}
              onClick={() =>
                this.setActiveTab({ activeAllBooks: true }, () =>
                  this.refreshGrid(true, null, dateRange)
                )
              }
            >
              <img src={"images/logo_2.png"} alt={"logo"} />
            </div>
            <div>
              <h3>
                Evaluation Web Dashboard <BlackKey />
              </h3>
            </div>
          </header>
          <div className="d-flex flex-wrap">
            <NavBar
              activeAllBooks={activeAllBooks}
              activeBasicBooks={activeBasicBooks}
              activeAdvancedBooks={activeAdvancedBooks}
              setActiveTab={this.setActiveTab}
              refreshGrid={this.refreshGrid}
              dateRange={dateRange}
              signOut={this.signOut}
            />
            <div className="maim-body">
              <Switch>
                <Route
                  exact
                  path={ROUTES.BOOKING}
                  render={() => (
                    <Booking
                      activeAllBooks={activeAllBooks}
                      activeBasicBooks={activeBasicBooks}
                      activeAdvancedBooks={activeAdvancedBooks}
                      evaluations={searchedEvaluation}
                      search={search}
                      handleChange={this.handleChange}
                      updateGrid={this.updateGrid}
                      onInputChange={this.onInputChange}
                      searchEvaluation={this.searchEvaluation}
                      setDateRange={this.setDateRange}
                      noSearchResults={noSearchResults}
                      dateRange={dateRange}
                    />
                  )}
                />
                <Route
                  exact
                  path={ROUTES.CREATE}
                  render={() => <Create updateGrid={this.updateGrid} />}
                />
                <Route
                  exact
                  path={ROUTES.EDIT}
                  render={() => <Create updateGrid={this.updateGrid} />}
                />
                <Route exact path={ROUTES.ADMIN} render={() => <Admin />} />
              </Switch>
            </div>
          </div>
        </div>
        <Footer />
        <ConfirmModal
          header={"Warning!"}
          body={"Do you want leave this page?"}
          modal={showLeaveModal}
          toggle={this.showLeaveModalToggle}
          onAccept={this.onLeaveModalAccept}
          showButton={true}
        />
      </Container>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withRouter
)(Main);

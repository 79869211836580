import app from "@firebase/app";
import {executeSearchQuery} from "./firestoreUtils";


const getUserCollection = () => app.firestore().collection("users");

export const getAllUser = () => {
    const query = buildSearchQuery();
    return executeSearchQuery(query);
};

export const updateUser = (dto, uid) => {
    return new Promise(function (resolve, reject) {
        const docRef = getUserCollection().doc(uid);
        docRef.update({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

export const deleteUser = (uid) => {
    return new Promise(function (resolve, reject) {
        const docRef = getUserCollection().doc(uid);
        docRef.delete().then(() => {
            resolve('deleted successfully')
        }).catch(() => {
            reject("Object not created");
        });
    })
};

export const deleteUserCloud = (uid) => {
    let deleteUser = app.functions().httpsCallable('delete');
    return deleteUser(uid);
};

export const createUser = (uid, user) => {
    getUserCollection().doc(uid).set({...user});
    return getUserByUid(uid);
};

export const createUserCloud = (email, password) => {
    let createUser = app.functions().httpsCallable('register');
    return createUser({email, password});
};


export const getUserByUid = (uid) => {
    let docRef = getUserCollection().doc(uid);
    return docRef.get().then(function (doc) {
        if (doc.exists) {
            return doc.data();
        } else {
            console.log("No such document!");
        }
    }).catch(function (error) {
        console.log("Error getting document:", error);
    });
};


const buildSearchQuery = () => {
    return getUserCollection();
};

export const initAlgoliaCloud = (data) => {
    let initAlgolia = app.functions().httpsCallable('addInitAlgolia');
    return initAlgolia(data);
};


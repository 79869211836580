import app from "@firebase/app";
import {executeSearchQuery} from "./firestoreUtils";


//const getReportCollection = () => app.firestore().collection("reportonlyfortest");
const getReportCollection = () => app.firestore().collection("report"); // Advance Report Collection
const getWheelTypeCollection = () => app.firestore().collection("wheelsandtyres"); // Advance Wheel and Tyres Collection
const getReportBasicCollection = () => app.firestore().collection("reportBasic"); // Basic Report Collection

// const execute Get Advance Report Collection
export const getAllReport = () => {
    const query = buildSearchQuery();
    return executeSearchQuery(query);
};

// const execute Get Basic Report Collection
export const getAllReportBasic = () => {
    const query = buildSearchQueryBasic();
    return executeSearchQuery(query);
};

// Get Advanced Report Items
export const getReportItems = (uid) => {
    const query = getReportCollection().doc(uid).collection("Items");
    return executeSearchQuery(query)
};

// Get Advanced Wheel and sand Items
export const getWheelTyresItems = (uid) => {
    const query = getWheelTypeCollection()
    return executeSearchQuery(query)
};

// Get Basic Report Items
export const getReportBasicItems = (uid) => {
    const query = getReportBasicCollection().doc(uid).collection("Items");
    return executeSearchQuery(query)
};

// Create Item on the Advance Report Collection
export const createItem = (reportUid, itemUid, dto) => {
    return new Promise(function (resolve, reject) {
        const docRef = getReportCollection().doc(reportUid).collection("Items").doc(itemUid);
        docRef.set({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};


// Create Item on the Advance Report Collection
export const createWheelTyreItem = (itemUid, dto) => {
    return new Promise(function (resolve, reject) {
        const docRef = getWheelTypeCollection().doc(itemUid);
        docRef.set({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

// Create Item on the Basic Report Collection
export const createBasicItem = (reportUid, itemUid, dto) => {
    return new Promise(function (resolve, reject) {
        const docRef = getReportBasicCollection().doc(reportUid).collection("Items").doc(itemUid);
        docRef.set({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

// Delete Item on the Advance Report Collection
export const deleteItem = (reportUid, itemUid) => {
    return getReportCollection().doc(reportUid).collection("Items").doc(itemUid).delete()
};

// Delete Item on the Advance Report Collection
export const deleteWheelTyreItem = (itemUid) => {
    return getWheelTypeCollection().doc(itemUid).delete()
};

// Delete Item on the Basic Report Collection
export const deleteBasicItem = (reportUid, itemUid) => {
    return getReportBasicCollection().doc(reportUid).collection("Items").doc(itemUid).delete()
};

// Edit Item on the Advance Report Collection
export const editReportItem = (reportUid, itemUid, dto) => {
    return new Promise(function (resolve, reject) {
        const docRef = getReportCollection().doc(reportUid).collection("Items").doc(itemUid);
        docRef.update({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

// Edit Item on the Advance Report Collection
export const editWheelTyreItem = (itemUid, dto) => {
    return new Promise(function (resolve, reject) {
        const docRef = getWheelTypeCollection().doc(itemUid);
        docRef.update({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

// Edit Item on the Basic Report Collection
export const editReportBasicItem = (reportUid, itemUid, dto) => {
    return new Promise(function (resolve, reject) {
        const docRef = getReportBasicCollection().doc(reportUid).collection("Items").doc(itemUid);
        docRef.update({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

// Delete Item on the Advance Report Collection
export const deleteReportItem = (reportUid, itemUid, dto) => {
    return new Promise(function (resolve, reject) {
        const docRef = getReportCollection().doc(reportUid).collection("Items").doc(itemUid);
        docRef.set({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

// Delete Item on the Basic Report Collection
export const deleteReportBasicItem = (reportUid, itemUid, dto) => {
    return new Promise(function (resolve, reject) {
        const docRef = getReportBasicCollection().doc(reportUid).collection("Items").doc(itemUid);
        docRef.set({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

// Query get Advance Report Collection
const buildSearchQuery = () => {
    let query = getReportCollection();
    return query;
};

// Query get Basic Report Collection
const buildSearchQueryBasic = () => {
    let query = getReportBasicCollection();
    return query;
};

import React from 'react';
import ImageUploader from 'react-images-upload';
import {MAX_FILE_SIZE, ALLOWED_IMAGE_EXTENTIONS} from '../../../configs'

import './Uploader.css';
import {DownloadPhoto} from "../Icons/Icons";

export const Uploader = ({onDrop}) => {
    return (
        <ImageUploader
            withIcon={false}
            withLabel={false}
            singleImage={true}
            //withPreview
            maxFileSize={MAX_FILE_SIZE}
            imgExtension={ALLOWED_IMAGE_EXTENTIONS}
            fileContainerStyle={{
                background: 'none',
                boxShadow: 'none',
                padding: 0,
                margin: 0,
                width: '50px'
            }}
            buttonStyles={{
                margin: '0 0 0 10px' ,
                minWidth: '36px',
                minHeight: '36px',
                borderRadius: '50%',
                display: 'flex',
                backgroundColor: '#e00000',
                padding: '4px'
            }}
            buttonClassName={'downloadPhotoButton'}
            buttonText={<DownloadPhoto/>}
            onChange={onDrop}
        />
    );
};


import React from 'react';
import './UserIcon.css';

const UserIcon = ({userName}) => {
    const icon = userName.length > 0 ? userName.toUpperCase().split(' ') : ['UNKNOWN'];
    return(
        <div className={'userIcon'}>
            <p>{icon.length > 1 ? `${icon[0].charAt(0)}${icon[1].charAt(0)}` : icon[0].charAt(0)}</p>
        </div>
    )
};

export default UserIcon
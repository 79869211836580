import React, {useState, useEffect, Component} from 'react';
import './InspectionDetails.css';
import {FormGroup, Label} from "reactstrap";
import Input from "reactstrap/es/Input";
import DatePicker from "react-datepicker/es";
import {AdvancedIcon, BasicIcon, InspectionDetailsIcon} from "../../elements/Icons/Icons";
import {END_WORK_TIME, START_WORK_TIME, END_MINUTE_TIME} from "../../../configs";
import DotLine from "../../elements/DotLine/DotLine";
import {getInspectionsByTimeRange} from "../../../firebase/api/evaluation";
import {convertDateToFirestoreTimestamp} from "../../../firebase/api/firestoreUtils";
import LocationSearchInput from '../../elements/GooglePlaces/GooglePlaces';
import GoogleMap from '../../elements/GoogleMap/GoogleMap';

//import Autocomplete from 'react-google-autocomplete';

class InspectionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedule: [],
            dto: {
                name: '',
                latitude: props.vehicle.locationPos && props.vehicle.locationPos.latitude,
                longitude: props.vehicle.locationPos && props.vehicle.locationPos.longitude,
                radius: 0.5,
                nickname: null,
            },
            currentSchedule: []
        }
    }

    componentDidMount() {
        const {vehicle} = this.props;
        Date.prototype.daysInMonth = function () {
            return 32 - new Date(this.getFullYear(), this.getMonth(), 32).getDate();
        };
        this.monthChangeHandle(vehicle.date);
    }

    monthChangeHandle = (date) => {
        const {schedule} = this.state;
        const currentDate = new Date();
        let forDate;
        schedule.splice(0, schedule.length);

        if (date.getMonth() === currentDate.getMonth()) {
            for (let i = currentDate.getDate(); i <= date.daysInMonth(); i++) {
                schedule[i] = this.dayRender();
            }
            forDate = new Date(date.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 1, 0, 0, 0);
        } else {
            for (let i = 0; i <= date.daysInMonth(); i++) {
                schedule[i] = this.dayRender();
            }
            forDate = new Date(date.getFullYear(), date.getMonth(), 1, 1, 0, 0, 0);
        }

        const toDate = new Date(date.getFullYear(), date.getMonth(), date.daysInMonth(), 23, 0, 0, 0);
        getInspectionsByTimeRange(
            convertDateToFirestoreTimestamp(forDate),
            convertDateToFirestoreTimestamp(toDate),
        ).then(results => {
            results && results.forEach(function (doc) {
                const data = doc.data();
                const getDay = new Date(data.bookingDate.seconds * 1000).getDate();
                const getHour = new Date(data.bookingDate.seconds * 1000).getHours();
                schedule[getDay][getHour] && schedule[getDay][getHour].booked++;
            });
        });

        this.setState({schedule}, () => this.updateCurrentSchedule(date));


    };

    dayRender = () => {
        let arr = [];
        let minutes = [];
        for (let i = START_WORK_TIME; i <= END_WORK_TIME; i++) {
            const hour = i <= 9 ? `0${i}` : i;
            arr[i] = {
                hour,
                booked: 0,
            }
        }
        return arr;
    };

    getMinutesArray = () => {
        let arr = [];
        for (let i = START_WORK_TIME; i <= END_MINUTE_TIME; i++) {
            if (i % 5 === 0) {
                const min = i <= 5 ? `0${i}` : i;
                arr[i] = {
                    minutes: min,
                }
            }
        }
        return arr;
    }

    handleChange = date => {
        this.updateCurrentSchedule(date);

        this.props.onInputChange({
            target: {
                name: 'date',
                value: date,
            }
        })
    };

    updateCurrentSchedule = date => {
        const {schedule} = this.state;
        const scheduleByDate = schedule[date.getDate()];
        let currentSchedule = null;
        if (scheduleByDate) {
            currentSchedule = [...scheduleByDate];
        } else {
            currentSchedule = [...schedule];
        }
        this.setState({
            currentSchedule,
        }, () => console.log(currentSchedule));
    };
    getSelectedLocation = () => {
        const { vehicle, locations } = this.props;
        if (locations) {
            return locations.find(loc => loc.nickname === vehicle.locationName);
        }
        return null;
    }

    onLocationChange = (address, latLng, nickname) => {
        const { dto } = this.state;
        dto.name = address;
        dto.nickname = nickname;
        if (latLng) {
            dto.latitude = latLng.lat;
            dto.longitude = latLng.lng;
            const selectedLocation = this.getSelectedLocation();
            this.props.onLocationSet(address, selectedLocation, latLng.lat, latLng.lng)
        }
        this.setState({ dto });
    };

    render() {
        const { currentSchedule, dto} = this.state;
        const {onInputChange, vehicle, inspectionTypeToggle, locations} = this.props;
        const selectedLocation = this.getSelectedLocation();
        const isMobileLocation = selectedLocation && !selectedLocation.latitude && !selectedLocation.longitude;
        const minutes = this.getMinutesArray();
        return (
            <div id={'inspectionDetails'}>
                <div className={'createInspectionHeader'}>
                    <InspectionDetailsIcon/>
                    <div>
                        <h3>Inspection and Calendar details</h3>
                        <p>Please make sure these details are up to date as they’ll be used for your booking
                            Inspections and communications.</p>
                    </div>
                </div>
                <div className={'createInspectionBody'}>
                    <div className='inspectionTypeWrapper flex-wrap d-flex align-items-end'>
                        <Label>Choose type of Inspection</Label>
                        <div className='d-flex'>
                            <div className={`inspectionType ${vehicle.inspectionType === 'Basic' && 'active'}`}
                                 onClick={() => inspectionTypeToggle('Basic')}>
                                <p><BasicIcon/>Standard</p>
                            </div>
                            <div className={`inspectionType ${vehicle.inspectionType === 'Advanced' && 'active'}`}
                                 onClick={() => inspectionTypeToggle('Advanced')}>
                                <p><AdvancedIcon/>Advanced</p>
                            </div>
                        </div>
                    </div>
                    <div className={'greyLine'}/>
                    <div className='d-flex mt-3 justify-content-between'>
                        <FormGroup className='createFormGroup d-flex flex-row createFormGroup '>
                            <Label for="chooseLocation">Choose Location</Label>
                            <Input type="select" name="locationName" id="chooseLocation" value={vehicle.locationName}
                                   onChange={event => onInputChange(event)}>
                                <option/>
                                {locations && locations.map((location, index) => 
                                    <option value={location.name} key={index}>{location.nickname}</option>
                                )}
                            </Input>

                            {/*<Autocomplete*/}
                            {/*    style={{width: '90%'}}*/}
                            {/*    onPlaceSelected={(place) => {*/}
                            {/*        console.log(place);*/}
                            {/*    }}*/}
                            {/*    types={['(regions)']}*/}
                            {/*    componentRestrictions={{country: "ru"}}*/}
                            {/*/>*/}
                       

                        </FormGroup>
    
                    </div>
                    {dto && <div style={{ marginTop: '10px', height: 200 }} className={isMobileLocation ? 'test' : "map-hide"}>
                        <GoogleMap lat={dto.latitude} lng={dto.longitude} setLocation={this.onLocationChange}/>
                    </div>}
                    {isMobileLocation && (<div style={{ marginTop: '20px',marginBottom: '20px' }}>
                        <LocationSearchInput
                            location={dto}
                            onLocationChange={this.onLocationChange}
                        />
                            <Label for="addressNotes" style={{ marginTop: '20px' }}>Address Notes</Label>
                            <Input type="text"
                                   name="addressNotes"
                                   className="form-control"
                                   id="addressNotes"
                                   placeholder="Address Notes"
                                   value={vehicle.addressNotes}
                                   onChange={(event => onInputChange(event))}
                                   style={{ marginBottom: '20px' }}
                            />

                    </div>)}
                    <div className='d-flex justify-content-between flex-wrap mt-2'>
                        <div className='d-flex align-items-start mb-2'>
                            <label>Choose Date </label>
                            <div className='d-flex flex-wrap'>
                            <DatePicker
                                inline
                                selected={vehicle.date}
                                onChange={this.handleChange}
                                minDate={new Date()}
                                onMonthChange={this.monthChangeHandle}
                            />
                                <div className={'inspectionSchedule'}>
                                    {currentSchedule.length > 0 && currentSchedule.map((time, index) => (
                                        time && time.booked > 0 &&
                                        <div className='d-flex' key={index}>
                                            <DotLine/>
                                            <div className='d-flex flex-column justify-content-start'>
                                                <p key={index}>
                                                    <span>{parseInt(time.hour) < 10 ? `${time.hour}` : time.hour}:00</span>&nbsp;{time.booked > 0 ? ` - ${time.booked} Inspection booked` : ` - `}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    <div className='flex-wrap d-flex align-items-end'>
                        <Label for="chooseTime">Choose Time</Label>
                        <div className='d-flex' style={{width:'300px', alignItems: 'center'}}>
                        <Input type="select" value={vehicle.hour} onChange={(event) => onInputChange(event)} name="hour" id="chooseTime">
                        <option value='' disabled="disabled">HH</option>
                            {currentSchedule.length > 0 && currentSchedule.map((time, index) => (
                                time &&
                                <option value={time.hour} key={index}>{time.hour}</option>
                            ))}
                        </Input>
                        <Input type="select" style={{
                            marginLeft: 10
                        }} onChange={(event) => onInputChange(event)} value={vehicle.minutes} name="minutes" id="chooseTime">
                        <option value='' disabled="disabled">MM</option>
                            {minutes.length > 0 && minutes.map((time, index) => (
                                time &&                            
                                <option value={time.minutes} key={index}>{time.minutes}</option>
                            ))}
                        </Input>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InspectionDetails
import React from 'react';
import './AddUserForm.css'
import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
import {USER_ROLES} from "../../../configs";
import {doCreateUserWithEmailAndPassword} from "../../../firebase/api/auth";
import {ifStringEmpty} from "../../../static/utils";
import {createUser, createUserCloud, getUserByUid} from "../../../firebase/api/users";
import Loader from "../../elements/Loader/Loader";

class AddUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddUser: false,
            showLoader: false,
            dto: {
                email: "",
                password: "",
                name: "",
                location: "",
                role: "",
            },
            error: "",
        };
    }

    showUserToggle = () => {
        this.setState(prevState => ({
            showAddUser: !prevState.showAddUser
        }));
    };

    onInputChange = event => {
        const {dto} = this.state;
        dto[event.target.name] = event.target.value;
        this.setState({dto});
    };

    addUser = event => {
        event.preventDefault();
        const {dto} = this.state;
        this.setState({showLoader: true});


        createUserCloud(dto.email, dto.password).then(result => {
            if (result.data.user) {
                const user = {
                    name: dto.name,
                    role: dto.role,
                    location: dto.location,
                    email: result.data.user.email,
                    uid: result.data.user.uid
                };

                createUser(result.data.user.uid, user).then(res => {
                    if (res) {
                        //console.log('sdf');
                        this.props.addNewUserRow(user);
                        dto.email = "";
                        dto.password = "";
                        dto.name = "";
                        dto.location = "";
                        dto.role = "";
                        this.setState({dto, showLoader: false, showAddUser: false});
                    } else {
                        this.setState({showLoader: false});
                    }
                });

            } else {
                result.data.error && this.setState({error: result.data.error});
                this.setState({showLoader: false});
            }
        }).catch(() => {
            this.setState({error: 'Some error'});
            this.setState({showLoader: false});
        })
    };

    render() {
        const {showAddUser, dto, error, showLoader} = this.state;
        const {locations} = this.props;
        const isValid = dto.name && dto.location && dto.role && dto.email && dto.password;
        return (
            <div className='new-user-wrapper'>
                <div className='new-user-buttons'>
                    <Button color="danger"
                            onClick={() => this.showUserToggle()}>{showAddUser ? "Back" : "Add User"}</Button>
                    {showAddUser &&
                    <Button color="info" disabled={!isValid} className='ml-2' onClick={event => this.addUser(event)}>Add
                        User</Button>}
                </div>
                {showAddUser && <Form className="add-user-form">
                    {showLoader ? <div className='w-100' style={{minHeight: '172px'}}>
                            <span className='m-auto'>
                                <Loader/>
                            </span>
                        </div>
                        : <>
                            <div className='w-100 justify-content-between flex-wrap'>
                                <FormGroup className="w-50 pr-1">
                                    <Label for="exampleEmail">Email</Label>
                                    <Input type="email"
                                           name="email"
                                           id="exampleEmail"
                                           placeholder="Email"
                                           value={dto.email}
                                           onChange={event => this.onInputChange(event)}
                                    />
                                </FormGroup>
                                <FormGroup className="w-50 pl-1">
                                    <Label for="examplePassword">Password</Label>
                                    <Input type="password"
                                           name="password"
                                           id="examplePassword"
                                           placeholder="Password"
                                           value={dto.password}
                                           onChange={event => this.onInputChange(event)}
                                    />
                                </FormGroup>
                            </div>
                            <div className='w-100 justify-content-between flex-wrap'>
                                <FormGroup className='add-user-custom-size'>
                                    <Label for="exampleName">Name</Label>
                                    <Input type="text"
                                           name="name"
                                           id="exampleName"
                                           placeholder="Name"
                                           value={dto.name}
                                           onChange={event => this.onInputChange(event)}
                                    />
                                </FormGroup>
                                <FormGroup className='add-user-custom-size'>
                                    <Label for="exampleLocation">Location</Label>
                                    <Input name="location"
                                           type="select"
                                           placeholder="Location"
                                           value={dto.location}
                                           onChange={event => this.onInputChange(event)}
                                    >
                                        <option/>
                                        {locations && locations.map((location, index) => (
                                            <option key={index} value={location.name}>{location.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup className='add-user-custom-size'>
                                    <Label for="exampleEmail">Role</Label>
                                    <Input name="role"
                                           type="select"
                                           placeholder="Role"
                                           value={dto.role}
                                           onChange={event => this.onInputChange(event)}
                                    >
                                        <option/>
                                        {USER_ROLES && USER_ROLES.map((role, index) => (
                                            <option key={index} value={role}>{role}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>
                        </>}
                    <p className="error-message">{error}</p>
                </Form>}
            </div>
        );
    }
}

export default AddUserForm;
import React, {Component} from "react";
import {doSignInWithEmailAndPassword} from "../../../firebase/api/auth";
import {doPasswordReset} from "../../../firebase/api/auth";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import ConfirmationModal from "../../elements/modal/ConfirmModal";
import MaterialIcon from "material-icons-react";

import * as ROUTES from "../../../constants/routes";
import './SignIn.css'
import {Form, FormGroup, Label, Input, Button} from "reactstrap";


const INITIAL_STATE = {
    email: "",
    password: "",
    error: null,
    alertError:'',
    showResetModal: false
};

class SignInForm extends Component {
    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};
    }
    resetPasswordModalToggle = () => {
        const {email} = this.state;
        if (email === '') {
            this.setState({
                alertError: 'Please enter email id'
            })
        } else {
            this.setState(prevState => ({
                showResetModal: !prevState.showResetModal
            }));
        }
    };
    resetPasswordModalSentToggle = () => {
        this.setState(prevState => ({
            showResetModalSent: !prevState.showResetModalSent
        }));
    };

    resetPassword = () => {
        const {email, password} = this.state;
        email && doPasswordReset(email).then(res => {
            this.resetPasswordModalToggle();
            this.resetPasswordModalSentToggle();            
        })
    };

    onSubmit = event => {
        const {email, password} = this.state;
        doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({...INITIAL_STATE});
                this.props.onClose && this.props.onClose();
                this.props.history.push(ROUTES.BOOKING);
            })
            .catch(error => {
                this.setState({error});
            });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {
        const {email, password, error, showResetModal, showResetModalSent,alertError} = this.state;
        const {user} = this.props;
        const isInvalid = password === "" || email === "";
        const isInvalidForResetPassword = email === "";
        return (
            <Form className='signInForm' autoComplete="new-password" action="" method="post" name="login" onSubmit={this.onSubmit}>
                <FormGroup>
                    <Label for="exampleInputEmail1">Email address</Label>
                    <Input type="email" autoComplete="new-password" name="email" className="form-control" id="email"
                           aria-describedby="emailHelp" placeholder="Email"
                           onChange={this.onChange}/>
                </FormGroup>
                <FormGroup>
                    <Label className='d-flex justify-content-between' for="exampleInputEmail1"><span>Password</span>
                    <span className={'resetPassword'} 
                                    onClick={() => this.resetPasswordModalToggle()}
                                    disabled={isInvalidForResetPassword}>Forgot your password?</span></Label>
                    <Input type="password" autoComplete="new-password" name="password" id="password"
                           className="form-control"
                           aria-describedby="emailHelp" placeholder="Password"
                           onChange={this.onChange}/>
                </FormGroup>
                            <ConfirmationModal toggle={this.resetPasswordModalToggle}
                                               onAccept={this.resetPassword}
                                               modal={showResetModal}
                                               header={'Reset password'}
                                               body={`Are you sure you want to reset ${email} password?`}
                                               showButton={true}
                            />
                            <ConfirmationModal toggle={this.resetPasswordModalSentToggle}                                               
                                               modal={showResetModalSent}
                                               header={'Password Reset'}
                                               body={'We have just sent you a link to reset your password.'}                                               
                            />
                <Button
                    color="danger"
                    type="submit"
                    disabled={isInvalid}
                >
                    Sign in
                </Button>
                {alertError&& <p>{alertError}</p>}
                {error && <p>The password is invalid or the user does not have a password</p>}
            </Form>

        );
    }
}

export default compose(withRouter)(SignInForm);

import React, { Component } from "react";
import { compose } from "recompose";
import "./Grid.css";
import { withAuthorization } from "../../../session/index";
import Inspection from "../Inspection/Inspection";
import Loader from "../../elements/Loader/Loader";
import Evaluation from "../Evaluation/Evaluation";

class Grid extends Component {
  render() {
    const {
      evaluations,
      noSearchResults,
      onInputChange,
      search,
      value,
      dateRange,
      setDateRange
    } = this.props;
    return (
      // <div className={'grid'}>
      //     {evaluations.length > 0 ? evaluations.map((evaluation, index) => {
      //         return (
      //             <Inspection key={index} evaluation={evaluation}/>
      //         )
      //     }) : <div className={'loaderWrapper'}>{noSearchResults ? 'No search results...' :<Loader/>}</div>
      //     }
      //     {/* <Evaluation  evaluations={evaluations}/> */}
      // </div>

      <Evaluation
        evaluations={evaluations}
        search={search}
        onInputChange={onInputChange}
        value={value}
        setDateRange={setDateRange}
        dateRange={dateRange}
      />
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition))(Grid);

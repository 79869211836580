import app from "@firebase/app";
import {randomKey} from '../../static/utils'


export const uploadFile = (folderName, fileName, file) => {
    return app.storage().ref(folderName + '/' + fileName).put(file);
};

export const uploadFileWithMetadata = (folderName, fileName, file, metadata) => {
    return app.storage().ref(folderName + '/' + fileName).put(file, metadata);
};

export const uploadFileWithFilePathAndMetadata = (filePath, file, metadata) => {
    return app.storage().ref(filePath).put(file, metadata);
};


export const updateMetadata = (ref, metadata) => {
    return ref.updateMetadata(metadata);
};

export const getDownloadUrlFile = (ref) => {
    return ref.getDownloadURL();
};


export const deleteFile = (fullPath) => {
    return app.storage().ref(fullPath).delete();
};
import {ALGO_DRIVEN_AUTH_KEY} from "../configs";

const createRequest = ({method}) => (
    function request({path, body, isFormData}) {
        const requestBody = {
            headers: {
                'Authorization': ALGO_DRIVEN_AUTH_KEY,
            },
            method,
        };

        if (body) {
            if (isFormData) {
                requestBody.body = body;
            } else {
                requestBody.body = JSON.stringify(body);
                requestBody.headers['Content-Type'] = 'application/json';
            }
        }
        return fetch(path, requestBody)
            .then((response) => {
                if (response.ok) {
                    return response.text().then(function (text) {
                        return text ? isJSON(text) ? JSON.parse(text) : text : {}
                    });
                } else if (response.status === 400 || response.status === 500) {
                    throw response.json();
                } else if (response.status === 403) {
                }
            })
    }
);

function isJSON(text) {
    return /^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, '')) ? true : false;
}

export const api = {
    post: createRequest({method: 'POST'}),
    get: createRequest({method: 'GET'}),
    put: createRequest({method: 'PUT'}),
    delete: createRequest({method: 'DELETE'})
};

import React, { Component } from 'react';
import './ReportsTable.css';
import {
    createItem,
    deleteItem,
    deleteReportItem,
    editReportItem,
    getAllReport,
    getReportItems,
    getWheelTyresItems,
    createWheelTyreItem,
    editWheelTyreItem,
    deleteWheelTyreItem
} from "../../../firebase/api/report";
import ReportRow from "./ReportRow";
import Loader from "../../elements/Loader/Loader";
import { FormGroup, Input, Label, Table } from "reactstrap";
import MaterialIcon from "material-icons-react";
import FaultCodesTable from "../FaultCodesTable/FaultCodesTable";
import { isCategoryNameValid, isStringValid } from '../../../static/utils';


class ReportsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            itemsDisabled: false,
            addField: false,
            noItems: false,
            reports: {},
            items: {},
            activeItem: {},
            activeItemId: null,
            activeReportId: null,
            error: false,
            dto: {
                name: ''
            }
        }
    }

    componentDidMount() {
        const { reports } = this.state;
        this.setState({ showLoader: true });
        getAllReport().then(results => {
            if (results) {
                results.forEach(function (doc) {
                    reports[doc.ref.id] = doc.data();
                });
                delete (reports.OBD);
                delete reports['Onboard Diagnostics Fault Codes'];
                this.setState({ reports, showLoader: false }, () => {
                    const reportsKey = Object.keys(reports);
                    reportsKey && reportsKey.length > 0 && this.getItems(reportsKey[0]);
                });
            } else {
                this.setState({ reports, showLoader: false });
            }
        })
    };


    getWheelTyresAllItems = async (uid) => {
        let items = {};
        const results = await getWheelTyresItems(uid);
        if (results) {
            results.forEach(result => {
                items[result.ref.id] = {
                    issues: [],
                    ...result.data()};
            });
        }
    return items;
}


    getItems = async (uid) => {
        let items = {};
        let results = [];
        this.setState({ itemsDisabled: true, noItems: false, items: [] });
        if (uid === 'Wheels and Tyres') {
            items = await this.getWheelTyresAllItems(uid);
        } else {
            results = await getReportItems(uid);
            if (results) {
                results.forEach(result => {
                    items[result.ref.id] = result.data();
                });
            }
        }
        if (Object.keys(items).length === 0) {
            this.setState({ noItems: true })
        }
        this.setState({ items, itemsDisabled: false, activeReportId: uid, activeItemId: '' }, () => {
            const itemsKey = Object.keys(items);
            this.getField(itemsKey[0]);
        })
    };

    addNewItem = async () => {
        const { dto, activeReportId, items } = this.state;
        let result = '';
        const item = {
            issues: []
        };
        items[`${dto.name}`] = item;
        if (activeReportId === 'Wheels and Tyres') {
            result = await createWheelTyreItem(dto.name, item)
        } else {
            result = await createItem(activeReportId, dto.name, item)
        }
        if (result) {
            this.setState({ items }, () => this.resetInputs());
        }
    };

    editItem = async (dto, uid) => {
        let result = '';
        const { activeReportId, items } = this.state;
        if (activeReportId === 'Wheels and Tyres') {
            result = await createWheelTyreItem(dto.name, items[uid])
        } else {
            result = await createItem(activeReportId, dto.name, items[uid]);
        }

        if (result) {
            if (activeReportId === 'Wheels and Tyres') {
                deleteWheelTyreItem(uid);
            } else {
                deleteItem(activeReportId, uid);
            }
            items[`${dto.name}`] = items[uid];
            if (dto.name !== uid) {
                delete items[`${uid}`];
            }
            this.setState({ items }, () => this.resetInputs());
        }
    };

    deleteItem = async (uid, index, callback) => {
        const { activeReportId, items } = this.state;
        if (activeReportId === 'Wheels and Tyres') {
            await deleteWheelTyreItem(uid)
        } else {
            await deleteItem(activeReportId, uid);
        }
        delete items[`${uid}`];
        this.setState({ items }, () => callback());
    };

    resetInputs = () => {
        const { dto } = this.state;
        dto.name = "";
        this.setState({ dto });
    };

    getField = (uid) => {
        const { items } = this.state;
        const activeItem = items[uid];
        this.setState({ activeItem })
    };

    showFaultyCodes = (uid) => {
        const { activeItemId } = this.state;
        let newUid = uid;
        if (activeItemId === newUid) {
            newUid = '';
        }
        this.setState({ activeItemId: newUid })
    }

    onInputChange = event => {
        const { dto } = this.state;
        dto[event.target.name] = event.target.value;
        this.setState({ dto });
    };

    onEnterPressAdd = (event) => {
        const { dto } = this.state;
        if (event.key === 'Enter' && dto.name) {
            this.addNewItem()
        }
    };

    isAllItemNamesValid = (itemsKey) => {
        if (itemsKey && itemsKey.length > 0) {
            for (const key of itemsKey) {
                if (!isCategoryNameValid(key)) {
                    return false;
                }
            }

        }
        return true;
    }

    hasError = (isValid) => {        
        this.setState({
            error: !isValid
        })
    }

    render() {
        const { reports, items, error, activeItem, showLoader, addField, dto, itemsDisabled, noItems, activeReportId, activeItemId } = this.state;
    

        const reportsKey = Object.keys(reports);
        const itemsKey = Object.keys(items);
        const isButtonDisabled = isCategoryNameValid(dto.name);
        const isNamesValid = this.isAllItemNamesValid(itemsKey);
        return (
            <div className={'fault-codes-table'}>
                <div className='d-flex justify-content-between w-100'>
                    <FormGroup className='createFormGroup w-100'>
                        <Label for="customerInputName">Report</Label>
                        <Input name={'report'} type="select" placeholder={'Report'} value={activeReportId || ""}
                            onChange={event => this.getItems(event.target.value)}>
                            {reportsKey && reportsKey.map((report, index) => (
                                <option value={report} key={index}>{report}</option>
                            ))}
                        </Input>
                    </FormGroup>
                </div>
                {showLoader
                    ? <Loader />
                    : <Table bordered>
                        <thead>
                            <tr>
                                <th style={{ width: "85%" }}>Items</th>
                                <th style={{ width: "15%" }}>Options</th>
                            </tr>
                        </thead>
                        {noItems
                            ? <tbody>
                                <tr>
                                    <td />
                                    <td />
                                </tr>
                            </tbody>
                            : <tbody>
                                {itemsKey && itemsKey.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ReportRow
                                                showFaultyCodes={this.showFaultyCodes}
                                                editItem={this.editItem}
                                                deleteItem={this.deleteItem}
                                                key={index}
                                                hasError={this.hasError}
                                                index={index}
                                                activeReportId={activeReportId}
                                                showIcon={activeItemId === item}
                                                item={item} />
                                            {activeItemId === item && <FaultCodesTable
                                                items={items}
                                                activeReportId={activeReportId}
                                                activeItemId={item} activeItem={items[item]} />}
                                        </React.Fragment>
                                    )
                                })}
                                <tr>
                                    <td>{addField && <Input name={'name'} placeholder={'Field'} value={dto.name}
                                        onChange={event => this.onInputChange(event)}
                                        onKeyPress={event => this.onEnterPressAdd(event)}
                                    />}</td>
                                    <td>
                                        {addField
                                            ? <div className='d-flex'>

                                                <button className='option-button'
                                                    onClick={() => this.addNewItem()} 
                                                    disabled={!isButtonDisabled}>
                                                    <MaterialIcon icon="note" />
                                                </button>

                                                <button className='option-button'
                                                    onClick={() => {
                                                        this.setState({ addField: !addField }, () => this.resetInputs());
                                                    }}>
                                                    <MaterialIcon icon="reply" />
                                                </button>

                                            </div>
                                            : <button className='option-button'
                                                onClick={() => this.setState({ addField: !addField })}
                                            >
                                                <MaterialIcon icon="note_add" />
                                            </button>}
                                    </td>
                                </tr>
                                {/* {dto.name !== '' && !isButtonDisabled && <p className="error" style={{
                                    marginLeft: '12px'
                                }}>Category Name should not contain double spaces, special characters.</p>} */}
                            </tbody>}
                    </Table>}
                    {(!isNamesValid || error) && <p className="error">Category Name should not contain double spaces or special characters.</p>}

            </div>
        )
    }
}

export default ReportsTable
import React, {Component} from 'react';
import {Input} from "reactstrap";
import MaterialIcon from "material-icons-react";
import ConfirmationModal from "../../elements/modal/ConfirmModal";
import LocationSearchInput from "../../elements/GooglePlaces/GooglePlaces";
import GoogleMap from "../../elements/GoogleMap/GoogleMap";

class LocationRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editLocation: false,
            deleteModal: false,
            dto: {
                name: '',
                latitude: null,
                longitude: null,
                radius: 0.5,
                nickname:null,
            },
        }
    }

    componentDidMount() {
        this.setLocation()
    }

    onCancelEdit = () => {
        this.setLocation();
        const {editLocation} = this.state;
        this.setState({editLocation: !editLocation});
    };

    onLocationChange = (address, latLng, nickname) => {
        const {dto} = this.state;
        dto.name = address;
        dto.nickname = nickname;
        if (latLng) {
            dto.latitude = latLng.lat;
            dto.longitude = latLng.lng;
        }
        this.setState({dto});
    };

    setLocation = () => {
        const {dto} = this.state;
        const {location} = this.props;
        dto.name = location.name ? location.name : '';
        dto.latitude = location.latitude ? location.latitude : null;
        dto.longitude = location.longitude ? location.longitude : null;
        dto.radius = location.radius ? location.radius : 0.5;
        dto.nickname = location.nickname ? location.nickname: null;
        this.setState({dto});
    };

    deleteModalToggle = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    };

    deleteLocation = () => {
        const {index, deleteLocation, location} = this.props;
        deleteLocation(location.uid, index, this.deleteModalToggle);
    };

    editLocation = () => {
        const {index, editLocation, location} = this.props;
        const {dto} = this.state;
        editLocation(dto, location.uid, index);
        this.setState({editLocation: false});
    };

    onInputChange = event => {
        const {dto} = this.state;
        dto[event.target.name] = event.target.value;
        this.setState({dto});
    };

    onEnterPressAdd = (event) => {
        const {dto} = this.state;
        if (event.key === 'Enter' && dto.name & dto.nickname) {
            this.editLocation()
        }
    };

    render() {
        const {dto, editLocation, deleteModal} = this.state;
        const isButtonDisabled = dto.name;
        return (
            <tr>
                {/*<td>{editLocation*/}
                {/*    ? <Input name={'name'} placeholder={'Location'} value={dto.name}*/}
                {/*             onChange={event => this.onInputChange(event)}*/}
                {/*             onKeyPress={event => this.onEnterPressAdd(event)}*/}
                {/*    />*/}
                {/*    : dto.name}*/}
                {/*</td>*/}
                <td>{editLocation
                    ? <div className='locations-table-wrapper'>
                        {dto && dto.longitude && dto.latitude && <GoogleMap lat={dto.latitude} lng={dto.longitude}/>}
                        <div style={{
                            position: "relative",
                            zIndex: 99
                        }}>
                            <LocationSearchInput location={dto} onLocationChange={this.onLocationChange}/>
                        </div>
                    </div>
                    : dto.name}
                </td>
                <td>{editLocation
                    ? <Input name={'nickname'} placeholder={'Nickname'} value={dto.nickname}
                             onChange={event => this.onInputChange(event)}
                             onKeyPress={event => this.onEnterPressAdd(event)}
                    />
                    : dto.nickname}
                </td>
                <td>
                    {editLocation
                        ? <div className='d-flex'>

                            <button className='option-button'
                                    onClick={() => this.editLocation()} disabled={!isButtonDisabled}>
                                <i className="material-icons">save</i>
                            </button>

                            <button className='option-button'
                                    onClick={() => {
                                        this.onCancelEdit();
                                    }}>
                                <i className="material-icons">reply</i>
                            </button>

                        </div>
                        : <div className='d-flex'>

                            <button className='option-button'
                                    onClick={() => this.setState({editLocation: !editLocation})}
                            >
                                <MaterialIcon icon="create"/>
                            </button>

                            <button className='option-button'
                                    onClick={() => this.deleteModalToggle()}
                            >
                                <MaterialIcon icon="delete"/>
                            </button>

                            <ConfirmationModal toggle={this.deleteModalToggle}
                                               onAccept={this.deleteLocation}
                                               modal={deleteModal}
                                               header={'Are you sure you want to delete this Location?'}
                                               body={''}
                                               showButton={true}
                            />
                        </div>}
                </td>
            </tr>
        )
    }
}

export default LocationRow
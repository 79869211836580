import React from 'react';
import './ConfirmModal.css';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isString } from 'util';

const ConfirmModal = ({
  modal,
  toggle,
  header,
  body,
  showButton,
  onAccept,
  className,
  buttons,
  selected
}) => {
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={`confirmBookingModal ${className}`}
      >
        <ModalHeader toggle={toggle}>{header}</ModalHeader>
        {body && body.length > 0 && Array.isArray(body) && (
          <ModalBody>{body && [...body].map((elem, index) => <p key={index}>{elem}</p>)}</ModalBody>
        )}
        {body && !Array.isArray(body) && (
          <ModalBody>{body}</ModalBody>
        )}
        {showButton && (
          <ModalFooter>
            <div
              className={
                buttons && buttons[0]
                  ? `showBtn ${
                      selected === buttons[0]
                        ? 'showDeclineBtn'
                        : 'showAcceptBtn'
                    }`
                  : 'modalButton decline'
              }
              onClick={buttons && buttons[0] ? () => onAccept(buttons[0]) : toggle}
            >
              {buttons && buttons[0] ? <span>{buttons[0]}</span> : <span>&#x2715;</span>}
            </div>{' '}
            <div
              className={
                buttons && buttons[1] && isString(buttons[1])
                  ? `showBtn ${
                      selected === buttons[1]
                        ? 'showDeclineBtn'
                        : 'showAcceptBtn'
                    }`
                  : 'modalButton accept'
              }
              onClick={buttons && buttons[1] ? () => onAccept(buttons[1]) : onAccept}
            >
              {buttons && buttons[1] ? <span>{buttons[1]}</span> : <span>&#x2713;</span>}
            </div>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

export default ConfirmModal;

import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <div>
                <p>© Dubizzle Inspect 2019, All Rights Reserved.</p>
            </div>
        </footer>
    )
};

export default Footer
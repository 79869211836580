import React from 'react';
import './CustomerDetails.css';
import {Form, FormGroup, Label} from "reactstrap";
import Input from "reactstrap/es/Input";
import IntlTelInput from "react-intl-tel-input";
import {CustomDetailsIcon} from "../../elements/Icons/Icons";
import ErrorComponent from '../../elements/Error/Error';

const CustomerDetails = ({isVehicleOwner, isVehicleOwnerToggle, onInputChange, vehicle}) => {

    return (
        <div id={'customerDetails'}>
            <div className={'createInspectionHeader'}>
                <CustomDetailsIcon/>
                <div>
                    <h3>Customer Details</h3>
                    <p>Please make sure these details are up to date as they’ll be used for your booking
                        Inspections and communications.</p>
                </div>
            </div>
            <div className={'createInspectionBody'}>
                <div>
                    <h4>Customer Details</h4>
                    <div className={'redLine'}/>
                    <Form className='d-flex flex-column'>
                        <div className='d-flex flex-wrap'>
                            <FormGroup className='createFormGroup'>
                                <Label for="customerInputName">Full Name</Label>
                                <Input type="text"
                                       name="customerName"
                                       className="form-control"
                                       id="customerInputName"
                                       placeholder="Name"
                                       value={vehicle.customerName}
                                       onChange={(event => onInputChange(event))}
                                />
                                <ErrorComponent value={vehicle.customerName} type="string" name="Customer Name" />
                            </FormGroup>
                            <FormGroup className='createFormGroup'>
                                <Label for="customerInputEmail1">Email address</Label>
                                <Input type="email"
                                       name="customerMail"
                                       className="form-control"
                                       id="customerInputEmail1"
                                       aria-describedby="emailHelp"
                                       placeholder="Email"
                                       value={vehicle.customerMail}
                                       onChange={(event => onInputChange(event))}
                                />
                                <ErrorComponent value={vehicle.customerMail} type="email" name="Email Address" />
                            </FormGroup>
                        </div>
                        <FormGroup className='createFormGroup'>
                            <Label for="customerInputPhoneNumber">Phone Number</Label>
                            <IntlTelInput
                                preferredCountries={['ae']}
                                separateDialCode={true}
                                inputClassName={'form-control'}
                                inputName={'customerPhoneNumber'}
                                inputId={'customerInputPhoneNumber'}
                                onSelectFlag={(num, country) => {
                                    onInputChange({
                                        target: {
                                            name: 'customerPhoneNumber',
                                            value: num
                                        }
                                    });
                                    onInputChange({
                                        target: {
                                            name: 'customerCode',
                                            value: country.dialCode
                                        }
                                    });
                                }}
                                onPhoneNumberChange={(status, value, countryData, number, id) => {
                                    onInputChange({
                                        target: {
                                            name: 'customerPhoneNumber',
                                            value: value
                                        }
                                    });
                                    onInputChange({
                                        target: {
                                            name: 'customerCode',
                                            value: countryData.dialCode
                                        }
                                    });
                                }}
                                value={vehicle.customerPhoneNumber ? vehicle.customerPhoneNumber.toString() : ''}
                            />
                            <ErrorComponent value={vehicle.customerPhoneNumber} type="number" name="Customer phone number" />
                        </FormGroup>
                        <FormGroup className={'checkForm'}>
                            <Label check>
                                <input type="checkbox" value={isVehicleOwner} checked={isVehicleOwner} id="customerCheckbox"
                                       onChange={event => isVehicleOwnerToggle(event)}/>
                                Person requesting Inspection is vehicle owner
                            </Label>
                        </FormGroup>
                    </Form>
                </div>
                {!isVehicleOwner && <div>
                    <h4>Evaluations Details</h4>
                    <div className={'redLine'}/>
                    <Form className='d-flex flex-column'>
                        <div className='d-flex flex-wrap'>
                            <FormGroup className='createFormGroup'>
                                <Label for="ownerInputName">Full Name</Label>
                                <Input type="text"
                                       name="ownerName"
                                       className="form-control"
                                       id="ownerInputName"
                                       placeholder="Name"
                                       value={vehicle.ownerName}
                                       onChange={(event => onInputChange(event))}
                                />
                                <ErrorComponent value={vehicle.ownerName} type="string" name="Owner Name" />
                            </FormGroup>
                            <FormGroup className='createFormGroup'>
                                <Label for="ownerInputEmail1">Email address</Label>
                                <Input type="email"
                                       name="ownerMail"
                                       className="form-control"
                                       id="ownerInputEmail1"
                                       aria-describedby="emailHelp"
                                       placeholder="Email"
                                       value={vehicle.ownerMail}
                                       onChange={(event => onInputChange(event))}
                                />
                                <ErrorComponent value={vehicle.ownerMail} type="email" name="Owner Email" />
                            </FormGroup>
                        </div>
                        <FormGroup className='createFormGroup'>
                            <Label for="ownerInputPhoneNumber">Phone Number</Label>
                            <IntlTelInput
                                preferredCountries={['ae']}
                                inputClassName={'form-control'}
                                inputName={'ownerPhoneNumber'}
                                inputId={'ownerInputPhoneNumber'}
                                separateDialCode={true}
                                onSelectFlag={(num, country) => {
                                    onInputChange({
                                        target: {
                                            name: 'ownerPhoneNumber',
                                            value: num
                                        }
                                    });
                                    onInputChange({
                                        target: {
                                            name: 'ownerCode',
                                            value: country.dialCode
                                        }
                                    });
                                }}
                                onPhoneNumberChange={(status, value, countryData, number, id) => {
                                    onInputChange({
                                        target: {
                                            name: 'ownerPhoneNumber',
                                            value: value
                                        }
                                    });
                                    onInputChange({
                                        target: {
                                            name: 'ownerCode',
                                            value: countryData.dialCode
                                        }
                                    });
                                }}
                                value={vehicle.ownerPhoneNumber ? vehicle.ownerPhoneNumber.toString() : ''}
                            />
                            <ErrorComponent value={vehicle.ownerPhoneNumber} type="number" name="Owner phone number" />
                        </FormGroup>
                    </Form>
                </div>}
            </div>
        </div>
    );
};

export default CustomerDetails
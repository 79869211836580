import React, { Component } from 'react';
import { compose } from 'recompose';
import './Create.css';
import { withRouter } from 'react-router-dom';

import { withAuthorization } from '../../session/index';
import { Button } from 'reactstrap';

import 'react-intl-tel-input/dist/main.css';
import StepsControl from '../../components/elements/StepsContol/StepsControl';
import 'react-datepicker/dist/react-datepicker.css';
import CustomerDetails from '../../components/create/CustomerDetails/CustomerDetails';
import VehicleDetails from '../../components/create/VehicleDetails/VehicleDetails';
import InspectionDetails from '../../components/create/InspectionDetails/InspectionDetails';
import ConfirmationModal from '../../components/elements/modal/ConfirmModal';
import {
  getVehicleByMulkiya,
  getVehicleByVin,
  getVehicleImageByVin
} from '../../api/vehicle';
import * as ROUTES from '../../constants/routes';
import { convertDateToFirestoreTimestamp } from '../../firebase/api/firestoreUtils';
import {
  createInspection,
  getInspectionsByTime,
  getInspectionsByTimeRange,
  updateInspection,
  getInspectionId
} from '../../firebase/api/evaluation';
import { populateMetadata } from '../../static/mediaUtils';
import {
  getDownloadUrlFile,
  uploadFileWithFilePathAndMetadata
} from '../../firebase/api/media';
import { randomKey, isStringValid, isEmailValid, isNumberInvalid,isColorValid, isPlateEngineNumberValid } from '../../static/utils';
import { START_WORK_TIME } from '../../configs';
import { MAPS_KEY } from '../../configs';
import { getAllLocation } from '../../firebase/api/locations';
import {
  updateLocation
} from '../../firebase/api/locations';

import {getCarLogo} from '../../api/vehicle'
function getInspectionRefFromId(id) {
  if(id){
    return id.substr(0, 6).toUpperCase();
  } return ''; 
}

const dataURLToBlob = (dataURL) => {
  let BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
      let parts = dataURL.split(',');
      let contentType = parts[0].split(':')[1];
      let raw = parts[1];
      return new Blob([raw], {type: contentType});
  }

  let parts = dataURL.split(BASE64_MARKER);
  let contentType = parts[0].split(':')[1];
  let raw = window.atob(parts[1]);
  let rawLength = raw.length;

  let uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], {type: contentType});
};

const firebase = require('firebase');
class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle: {
        uid: null,
        date: new Date(),
        hour: '',
        minutes: '',
        customerName: '',
        customerMail: '',
        customerCode: '',
        customerPhoneNumber: '',
        ownerName: '',
        ownerMail: '',
        ownerCode: '',
        ownerPhoneNumber: '',
        vin: '',
        year: '',
        make: '',
        model: '',
        colour: '',
        plateNo: '',
        // kms: '',
        engineNumber: '',
        // gcc: 0,
        inspectionType: '',
        mulkiya: '',
        filename: '',
        locationName: '',
        carImages: [],
        inspectionRef: '',
        showDistanceTypeModal: false,
        selectedType: 'KMS',
        distanceUnit: 'Kms',
        locationPos: {
          latitude: '',
          longitude: ''
        }
      },
      errorKeys: {},
      step: 1,
      modal: false,
      confirmedModal: false,
      isVehicleOwner: false,
      bookingId: '',
      modals: [
        {
          header: 'Confirm Booking Inspection?',
          body: [],
          showButton: true
        },
        {
          header: 'Result not found',
          body: [],
          showButton: true
        }
      ],
      latitude: '',
      longitude: '',
      locations: [],
      addressNotes: '',
      locationPosName: ''
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.location.inspection &&
      this.setEditField(history.location.inspection);
    const { locations } = this.state;
    getAllLocation().then(results => {
      if (results) {
        results.forEach(function(doc) {
          const data = doc.data();
          data.uid = doc.ref.id;
          locations.push(data);
        });
        this.setState({ locations, showLoader: false });
      }
    });
  }

  setEditField = inspection => {
    const { vehicle } = this.state;
    const bookingDate =
      inspection && inspection.bookingDate
        ? new Date(inspection.bookingDate.seconds * 1000)
        : null;

    vehicle.date = bookingDate;
    const hour = bookingDate ? bookingDate.getHours() : new Date().getHours();
    const minutes = bookingDate ? bookingDate.getMinutes() : new Date().getMinutes();
    vehicle.hour = hour <= 9 ? '0' + hour : hour;
    vehicle.minutes = minutes <= 5 ? '0' + minutes : minutes;
    
    vehicle.uid = inspection.uid || inspection.objectID;
    vehicle.customerName = inspection.cName ? inspection.cName : '';
    vehicle.customerMail = inspection.cEmail ? inspection.cEmail : '';
    vehicle.customerCode = inspection.cPhCode ? inspection.cPhCode : '';
    vehicle.customerPhoneNumber = inspection.cPhNumber;
    vehicle.ownerName = inspection.vName ? inspection.vName : '';
    vehicle.ownerMail = inspection.vEmail ? inspection.vEmail : '';
    vehicle.ownerCode = inspection.vPhCode ? inspection.vPhCode : '';
    vehicle.ownerPhoneNumber = inspection.vPhNumber ? inspection.vPhNumber : '';
    vehicle.vin = inspection.vVIN.toUpperCase();
    vehicle.year = inspection.vYear;
    vehicle.make = inspection.vMake;
    vehicle.model = inspection.vModel;
    vehicle.plateNo = inspection.vPlate;
    // vehicle.kms = inspection.vKM;
    vehicle.distanceUnit = inspection.distanceUnit;
    vehicle.engineNumber = inspection.vEngineNo;
    // vehicle.gcc = inspection.vGCC;
    vehicle.colour = inspection.vColour;
    vehicle.inspectionType = inspection.inspectionType
      ? inspection.inspectionType
      : '';
    vehicle.locationPos = inspection.locationPos;
    vehicle.mulkiya = inspection.drivingLicence
      ? inspection.drivingLicence
      : '';
    vehicle.filename = '';
    vehicle.locationName = inspection.locationName
      ? inspection.locationName
      : '';
    vehicle.carImages = inspection && inspection.carImages ? [...inspection.carImages] : [];
    vehicle.addressNotes = inspection.addressNotes;
    const isVehicleOwner =
      inspection.cName === inspection.vName &&
      inspection.cEmail === inspection.vEmail &&
      inspection.cPhCode === inspection.vPhCode &&
      inspection.cPhNumber === inspection.vPhNumber;


    this.setState({ vehicle, isVehicleOwner }, () =>
      console.log(vehicle)
    );
  };

  modalToggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  confirmedModal = () => {
    this.setState(prevState => ({
      confirmedModal: !prevState.confirmedModal
    }));
  };

  acceptModalToggle = () => {
    this.modalToggle();
    this.createInspection();
  };

  timeCheck = () => {
    const { vehicle, modals } = this.state;
    modals[0].body = [];
    const {hour, minutes} = vehicle;
    vehicle.date.setHours(
      hour
        ? hour
        : new Date().getHours()
    );
    vehicle.date.setMinutes(minutes);
    vehicle.date.setSeconds(0);
    vehicle.date.setMilliseconds(0);
    const time = `${hour}:${minutes}`;
    getInspectionsByTime(convertDateToFirestoreTimestamp(vehicle.date)).then(
      results => {
        let count = 0;
        if (results) {
          results &&
            results.forEach(doc => {
              count++;                            
              const data = doc.data();
              
              /*
              modals[0].body.push(
                `Booking Reference Number: ${data.inspectionRef}`
              );
              modals[0].body.push(
                `Inspection Station No.${count}: ${
                  data.vName.length > 0 ? data.vName + '’s' : ''
                } car at ${time}.`
              );
              */              
            });
        }
        if(this.props.history.location.pathname != '/create') {
          if(vehicle.uid){
            if (count === 0) {
              modals[0].body.push(
                `Your Booking Reference Number: ${getInspectionRefFromId(vehicle.uid)}`
              );
              modals[0].header = `Confirm Booking Inspection?`;
              // modals[0].body.push(`Your Booking Reference Number: ${getInspectionRefFromId(vehicle.uid)}`);
            }else{
                modals[0].header = `Slot already Booked`;
                modals[0].body.push(`Another booking exists of some time - do you want to continue?`);
                modals[0].body.push(`If confirmed, this booking will be reference number: ${getInspectionRefFromId(vehicle.uid)}`);
            }
            this.setState({ modals, bookingId: vehicle.uid }, () => this.modalToggle());
          } else {
            this.setState({ modal: false,confirmedModal:false},()=>{
              this.setState({ showErrorModal: true});
            });

          }
         
        } else {
          getInspectionId().then(id => {
            if (count === 0) {
              modals[0].body.push(
                `Your Booking Reference Number: ${getInspectionRefFromId(id)}`
              );
            }
            this.setState({ modals, bookingId: id }, () => this.modalToggle());
          });
        }
       
       
      }
    );
  };

  errorModalToggle = () => {
    this.setState(prevState => ({
        showErrorModal: !prevState.showErrorModal,
    }));
};

  isVehicleOwnerToggle = event => {
    this.setState({ isVehicleOwner: event.target.checked });
  };

  onStepClick = step => {
    this.setState({ step });
  };


  uploadPhotos = (file) => {
    
    return new Promise(resolve => {
          // Ensure it's an image
          if(file.type.match(/image.*/)) {
             
              console.log('@uploadPhotos: An image has been loaded');
              // Load the image
              let reader = new FileReader();
              reader.onload = function (readerEvent) {
                  let image = new Image();
                  image.onload = function (imageEvent) {

                      // Resize the image
                      let canvas = document.createElement('canvas'),
                          max_size = 1500,// TODO : pull max size from a site config
                          width = image.width,
                          height = image.height;
                          //RESIZE only if any of the width/height is more than 1500. Else Do not resize.
                          if(width >  max_size ||  height > max_size){
                            if (width > height) {
                                if (width > max_size) {
                                    height *= max_size / width;
                                    width = max_size;
                                }
                            } else {
                                if (height > max_size) {
                                    width *= max_size / height;
                                    height = max_size;
                                }
                            }
                          }
                          canvas.width = width;
                          canvas.height = height;
                          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                          let dataUrl = canvas.toDataURL('image/jpeg');
                          let resizedImage = dataURLToBlob(dataUrl);
                          resolve(resizedImage);
                  }
                  image.src = readerEvent.target.result;
              }
              reader.readAsDataURL(file);
          }
    });
};


  getVehicleByMulkiya = async(picture, callback) => {
    const { vehicle } = this.state;
    //Process image Start
    let imgBlob;
    
    try{
      imgBlob = await this.uploadPhotos(picture[0]);
    }catch(ex){
      console.log('Error while resizing the image.' , ex.message);
    }
    if(imgBlob){
      console.log('Succesfully resized. Creating file from blob');
      imgBlob = new File([imgBlob], picture[0].name, {lastModified: new Date(), type:  picture[0].type});
    }else{
      console.log('Using original image as faced an error while resizing.');
      imgBlob = picture[0];
    }
    console.log(`Original size: ${picture[0].size}, Resized to: ${imgBlob.size}`);
    //Process image end.

    const body = new FormData();
    // body.append('image', picture[0]);
    body.append('image', imgBlob);
    body.append('followOn', 'gccfactoryvinext');
    getVehicleByMulkiya(body).then(res => {

      if (res && res.VehicleData) {
        vehicle.year = res.VehicleData.MY;
        vehicle.make = res.VehicleData.Make;
        vehicle.model = res.VehicleData.Model;
        vehicle.gcc = res.VehicleData.GCC;
        vehicle.vin =res.VehicleData.VIN;
        vehicle.colour = res.VehicleData.Ext_Colour;
        vehicle.engineNumber = res.VehicleData.EngineNo;
        this.setState({ vehicle });
      } else {
        this.confirmedModal();
      }
      // this.setState({ vehicle });
      // console.log(res.VehicleData.VIN, 'Vin found')
      // this.setState({ vehicle }, () => this.getVehicle(res.VehicleData.VIN, callback));
      this.setState({ vehicle },callback(vehicle.make, vehicle.model) );
    });
  };
  
  getVehicle = (vin, callback) => {
    const { vehicle } = this.state;
    const body = new FormData();
    body.append('vin', vin);
    getVehicleByVin(body).then(res => {
      if (res && res.VehicleData) {
        vehicle.year = res.VehicleData.MY;
        vehicle.make = res.VehicleData.Make;
        vehicle.model = res.VehicleData.Model;
        vehicle.gcc = res.VehicleData.GCC;
        vehicle.colour = res.VehicleData.Ext_Colour;
        vehicle.engineNumber = res.VehicleData.EngineNo;
        this.setState({ vehicle });
      } else {
        this.confirmedModal();
      }
      if (typeof callback == 'function') callback(vehicle.make, vehicle.model);
    });
  };

  /*
  getCarStockImg = (vin, callback) => {
    const { vehicle } = this.state;
    const body = new FormData();
    body.append('vin', vin);
    getVehicleImageByVin(body).then(res => {
      vehicle.carImages.splice(0, vehicle.carImages.length);
      vehicle.carImages.push(res[0].ImgURL);
      this.setState({ vehicle });
    });
  };
  */
  getCarStockImg = (vin, callback) => {
    const { vehicle } = this.state;
    const body = new FormData();
    body.append('vin', vin);
    getVehicleImageByVin(body).then(res => {
      vehicle.carImages.splice(0, vehicle.carImages.length);
      /*    
      res response: 
      - "baseurl": "https://d167m5cujtukpx.cloudfront.net/",
      - "filename": "bmw.png",
      - "url": "https://d167m5cujtukpx.cloudfront.net/bmw.png"
      - https://d167m5cujtukpx.cloudfront.net/fit-in/240x135/filters:upscale()/audi.png
      */
      var url = res.baseurl + 'fit-in/220x160/filters:upscale()/' + res.filename; 
      //var url = res.baseurl + res.filename; 
      vehicle.carImages.push(url);
      this.setState({ vehicle });
    });
  };



  onDrop = picture => {
    const { vehicle } = this.state;
    const media = picture.length < 2 ? picture[0] : picture[picture.length - 1];

    const metadata = populateMetadata(media);
    const mediaPath = 'drivingLicences' + '/' + randomKey(7) + media.name;

    uploadFileWithFilePathAndMetadata(mediaPath, media, metadata).then(ref => {
      getDownloadUrlFile(ref.ref).then(url => {
        vehicle.mulkiya = {
          downloadMediaUrl: url,
          mediaPath: mediaPath
        };
        vehicle.filename = media.name;
        this.setState({ vehicle });
      });
    });
  };

  onInputChange = event => {
    const { vehicle } = this.state; 
    let targetName = event.target.name;
    vehicle[targetName] = event.target.value;
    const {mulkiya, vin} = vehicle;
    if (targetName === 'make') {
      getCarLogo({
        make: event.target.value
      }).then((value) => {
        vehicle.carImages.splice(0, vehicle.carImages.length);
        var url = value.baseurl + 'fit-in/220x160/filters:upscale()/' + value.filename; 
        vehicle.carImages.push(url);
        this.setState({ vehicle });
      })
    }

    this.setState({ vehicle });
  };

  // handleBlur = event => {
  //   if (event.target.name === 'kms') {
  //     this.handleDistanceTypeModal();
  //   }
  // };

  inspectionTypeToggle = type => {
    const { vehicle } = this.state;
    vehicle.inspectionType = type;
    this.setState({ vehicle });
  };

  createInspection = () => {
    const { vehicle, isVehicleOwner, locations, bookingId, latitude, longitude } = this.state;
    const {hour, minutes} = vehicle;
    //vehicle.date.setHours(vehicle.time.split(':')[0]);
    vehicle.date.setHours(
      vehicle.hour
        ? Number(vehicle.hour)
        : new Date().getHours()
    );
    vehicle.date.setMinutes(Number(minutes));
    vehicle.date.setSeconds(0);
    vehicle.date.setMilliseconds(0);
    var lat, long, locationName;
    for (var i = 0; i < locations.length; i++) {
      if (locations[i].name == vehicle.locationName) {
        lat = locations[i].latitude;
        long = locations[i].longitude;
        locationName = locations[i].name;
      }
    }

    if (!lat) {
      lat = latitude
    }

    if (!long) {
      long = longitude
    }
    const currentYear = new Date().getFullYear();

    var gccvalue;
    if (typeof vehicle.gcc === "undefined" || vehicle.gcc === ""){
      gccvalue = -1;
    }else if(vehicle.gcc == true){
      gccvalue = 1;
    }else{
      gccvalue = 0;
    }
    const dto = {
      bookingDate: convertDateToFirestoreTimestamp(vehicle.date),

      cName: vehicle.customerName,
      cEmail: vehicle.customerMail,
      cPhCode: vehicle.customerCode,
      cPhNumber: vehicle.customerPhoneNumber,

      vName:
        isVehicleOwner && vehicle.customerName
          ? vehicle.customerName
          : vehicle.ownerName
          ? vehicle.ownerName
          : '',
      vEmail:
        isVehicleOwner && vehicle.customerMail
          ? vehicle.customerMail
          : vehicle.ownerMail
          ? vehicle.ownerMail
          : '',
      vPhCode: isVehicleOwner ? vehicle.customerCode : vehicle.ownerCode,
      vPhNumber: isVehicleOwner
        ? vehicle.customerPhoneNumber
        : vehicle.ownerPhoneNumber,

      iName: '',
      iEmail: '',
      iPhCode: '',
      iPhNumber: '',
      iPicture: '',

      carImages: [...vehicle.carImages],
      categoriesOrder: {},
      drivingLicence: vehicle.mulkiya,

      inspectionType: vehicle.inspectionType,

      locationName: locationName,
      locationPos:
        (lat != null) & (long != null)
          ? new firebase.firestore.GeoPoint(Number(lat), Number(long))
          : new firebase.firestore.GeoPoint(0.0, 0.0),

      signImage: '',

      status: 'Pending',
      vEngineNo: (typeof vehicle.engineNumber === 'undefined' || vehicle.engineNumber === null) ? '' : vehicle.engineNumber,
      vGCC: gccvalue,
      // vKM: vehicle.kms || '',
      // vMILES: vehicle.miles || '',
      distanceUnit: vehicle.distanceUnit,
      vMake: vehicle.make,
      vModel: vehicle.model,
      vPlate: vehicle.plateNo,
      vVIN: vehicle.vin.toUpperCase(),
      vYear: vehicle.year || currentYear - 20,
      vColour: (typeof vehicle.colour === 'undefined' || vehicle.colour === null) ? '' : vehicle.colour,
      pdfURLShort: '',
      completed: convertDateToFirestoreTimestamp(vehicle.date),
      sent: convertDateToFirestoreTimestamp(vehicle.date),
      addressNotes: vehicle.addressNotes || '',
      locationPosName: vehicle.locationPosName || ''
    };
    this.props.history.location.pathname === '/create'
      ? createInspection(dto, bookingId).then(result => {
          if (result) {
            this.props.updateGrid(() => {
              this.props.history.push(ROUTES.BOOKING);
            });
          }
        })
      : updateInspection(dto, vehicle.uid).then(result => {
          if (result) {
            this.props.updateGrid(() => {
              this.props.history.push(ROUTES.BOOKING);
            });
          }
        });
  };

  handleDistanceTypeModal = () => {
    this.setState(prevState => ({
      showDistanceTypeModal: !prevState.showDistanceTypeModal
    }));
  };



  checkValidations = () => {
    const { step } = this.state;
    if (step === 1) {
      return this.checkValidationForStepOne();
    } else if (step === 2) {
      return this.checkValidationForStepTwo();
    } else if (step === 3) {
      return this.checkValidationForStepThree();
    }
    return false;
  }

  checkValidationForStepThree = () => {
    const { vehicle, isVehicleOwner } = this.state;
    const {
      inspectionType,locationName,
      date,
      hour, minutes } = vehicle
    let data = {
      inspectionType,
      locationName,
      date,
      hour,
      minutes
    }
    const values = Object.values(data);
    if (values.includes('') || values.includes(undefined)) {
      return true;
    }
    return false;
  }

  checkValidationForStepTwo = () => {
    const { vehicle, isVehicleOwner } = this.state;
    const {
      vin,
      filename,
      year,
      make,
      model,
      plateNo,
      engineNumber,
      colour } = vehicle
      let data = {
        year,
        make,
        model,
        plateNo,
      }
      if ((plateNo && !isPlateEngineNumberValid(plateNo)) || (engineNumber && !isPlateEngineNumberValid(engineNumber)) || (colour && !isColorValid(colour))){
        return true
      }
      const values = Object.values(data);
      if (values.includes('') || values.includes(undefined)) {
        return true;
      } else  {
        return false
      }
      if (vin !== '' && vin !== undefined) {
        return false
      }
      if (filename !== '' && filename !== undefined) {
        return false
      }
    // if (isNumberInvalid(kms)) {
    //   return true;
    // }
    return true;
  }

  checkValidationForStepOne = () => {
    const { vehicle, isVehicleOwner } = this.state;
    const {
      customerName,
      customerMail,
      customerCode,
      customerPhoneNumber,
      ownerName,
      ownerMail,
      ownerCode,
      ownerPhoneNumber } = vehicle
    let data = {
      customerName,
      customerMail,
      customerPhoneNumber,
    }
    if (!isVehicleOwner) {
      data = {
        ...data,
        ownerName,
        ownerMail,
        ownerPhoneNumber
      }
    }
    const values = Object.values(data);
    if (values.includes('') || values.includes(undefined)) {
      return true;
    }
    if (!isStringValid(customerName) || (!isVehicleOwner && !isStringValid(ownerName))) {
      return true;
    }
    if (isNumberInvalid(customerPhoneNumber) || (!isVehicleOwner && isNumberInvalid(ownerPhoneNumber))) {
      return true;
    }
    if (!isEmailValid(customerMail) || (!isVehicleOwner && !isEmailValid(ownerMail))) {
      return true;
    }
    return false;
  }

  setLocation=(address, selectedLocation, latitude, longitude) => {
    const {vehicle} = this.state;
    vehicle.locationPosName = address;
    // if (selectedLocation && latitude && longitude) {
    //   const dto = {
    //     ...selectedLocation,
    //     latitude,
    //     longitude
    //   }
    //   updateLocation(dto, selectedLocation.uid).then(result => {
    //     if (result) {
    //       const { locations} = this.state;
    //       const updatedLocations = locations.map((loc) => {
    //         if (loc.uid === selectedLocation.uid) {
    //           return dto
    //         }
    //         return loc
    //       })
    //       // this.setState({ locations: updatedLocations });
    //     }
    //   });
    // }
    this.setState({ latitude, longitude, vehicle})
  }

  distanceTypeModalToggle = type => {
    const { vehicle } = this.state;
    vehicle['distanceUnit'] = type;
    this.setState(prevState => ({
      showDistanceTypeModal: !prevState.showDistanceTypeModal,
      vehicle
    }));
  };

  render() {
    const {
      step,
      modal,
      isVehicleOwner,
      modals,
      confirmedModal,
      vehicle,
      locations,
      showDistanceTypeModal,
      selectedType,
      showErrorModal
    } = this.state;
    const isDisable = this.checkValidations();
    return (
      <div id={'createPage'}>
        <h1>Book Inspection</h1>
        {/*<p>&#x276F; Learn more about how to book an Inspection</p>*/}
        <div className={'createInspection'}>
          {step === 1 && (
            <CustomerDetails
              onInputChange={this.onInputChange}
              isVehicleOwner={isVehicleOwner}
              isVehicleOwnerToggle={this.isVehicleOwnerToggle}
              vehicle={vehicle}
            />
          )}
          {step === 2 && (
            <VehicleDetails
              onDrop={this.onDrop}
              onInputChange={this.onInputChange}
              getVehicle={this.getVehicle}
              getVehicleByMulkiya={this.getVehicleByMulkiya}
              getCarStockImg={this.getCarStockImg}
              vehicle={vehicle}
              handleDistanceTypeModal={this.handleDistanceTypeModal}
            />
          )}
          {step === 3 && (
            <InspectionDetails
              locations={locations}
              inspectionTypeToggle={this.inspectionTypeToggle}
              onInputChange={this.onInputChange}
              onLocationSet={this.setLocation}
              vehicle={vehicle}
            />
          )}

          <div className="createInspectionButton">
            {step > 1 && (
              <Button
                color="secondary"
                className="my-3"
                onClick={() => {
                  this.setState({ step: step - 1 });
                }}
              >
                Previous
              </Button>
            )}

            {step < 3 && (
              <Button
                color="danger"
                className="m-3"
                disabled={isDisable}
                style={isDisable ? {
                  opacity: 0.5
                } : {}}
                onClick={() => {
                  this.setState({ step: step + 1 });
                }}
              >
                Next
              </Button>
            )}

            {step > 2 && (
              <Button
                color="danger"
                className="m-3"
                disabled={isDisable}
                onClick={() => {
                  this.timeCheck();
                }}
                style={isDisable ? {
                  opacity: 0.5
                } : {}}
              >
                {this.props.history.location.pathname === '/create'
                  ? 'Booking'
                  : 'Save'}
              </Button>
            )}
          </div>
        </div>
        <StepsControl onStepClick={this.onStepClick} step={step} />
        <ConfirmationModal
          toggle={this.modalToggle}
          onAccept={this.acceptModalToggle}
          modal={modal}
          header={modals[0].header}
          body={modals[0].body}
          showButton={modals[0].showButton}
        />
        <ConfirmationModal
          toggle={() => this.confirmedModal()}
          modal={confirmedModal}
          header={modals[1].header}
          body={modals[1].body}
          showButton={modals[1].showButton}
          onAccept={() => {
            this.confirmedModal();
          }}
          className={'router'}
        />
        <ConfirmationModal
          modal={showDistanceTypeModal}
          header={'Distance Units'}
          body={'Select the distance unit'}
          showButton={true}
          selected={vehicle.distanceUnit || 'Kms'}
          buttons={['Kms', 'Miles']}
          onAccept={this.distanceTypeModalToggle}
          toggle={this.handleDistanceTypeModal}
        />
        <ConfirmationModal toggle={this.errorModalToggle}
                    modal={showErrorModal}
                    header={'Error'}
                    body={`Something wrong happened on new/edit booking.`}
                    showButton={false}
                />
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withRouter
)(Create);

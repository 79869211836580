import React, {Component} from 'react';
import {Input} from "reactstrap";
import MaterialIcon from "material-icons-react";
import { isCategoryNameValid } from '../../../static/utils';
import ConfirmationModal from "../../elements/modal/ConfirmModal";

class FaultCodeRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editReport: false,
            deleteModal: false,
            dto: {
                name: '',
            },
        }
    }

    componentDidMount() {
        this.setReport()
    }

    onCancelEdit = () => {
        this.setReport();
        const {editReport} = this.state;
        this.setState({editReport: !editReport});
    };

    setReport = () => {
        const {dto} = this.state;
        const {item} = this.props;
        dto.name = item ? item : '';
        this.setState({dto});
    };

    deleteModalToggle = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    };


    onInputChange = event => {
        const {dto} = this.state;
        const {hasError} = this.props;
        dto[event.target.name] = event.target.value;
        const nameIsValid = isCategoryNameValid(event.target.value);
        hasError(nameIsValid);
        this.setState({dto});
    };


    deleteReport = () => {
        const {index, deleteReport} = this.props;
        deleteReport(index, this.deleteModalToggle);
    };


    editReport = () => {
        const {index, editReport} = this.props;
        const {dto} = this.state;
        editReport(dto, index);
        this.setState({editReport: false});
    };

    onEnterPressAdd = (event) => {
        const {dto} = this.state;
        if(event.key === 'Enter' && dto.name){
            this.editReport()
        }
    };

    render() {
        const {dto, editReport, deleteModal} = this.state;
        const {item} = this.props;
        const isButtonDisabled = isCategoryNameValid(dto.name);
        return (
            <tr>
                <td>{editReport
                    ? <Input name={'name'} placeholder={'Field'} value={dto.name}
                             onChange={event => this.onInputChange(event)}
                             onKeyPress={event => this.onEnterPressAdd(event)}
                    />
                    : item}
                    {/* {dto.name !== '' && !isButtonDisabled && <p className="error">Fault category name should not contain double spaces, special characters except -.</p>} */}
                </td>
                <td>
                    {editReport
                        ? <div className='d-flex'>

                            <button className='option-button'
                                    onClick={() => this.editReport()} disabled={!isButtonDisabled}>
                                <i className="material-icons">save</i>
                            </button>

                            <button className='option-button'
                                    onClick={() => {
                                        this.onCancelEdit();
                                    }}>
                                <i className="material-icons">reply</i>
                            </button>

                        </div>
                        : <div className='d-flex'>

                            <button className='option-button'
                                    onClick={() => {
                                        this.setReport();
                                        this.setState({editReport: !editReport});
                                    }}
                            >
                                <MaterialIcon icon="create"/>
                            </button>

                            <button className='option-button'
                                    onClick={() => this.deleteModalToggle()}
                            >
                                <MaterialIcon icon="delete"/>
                            </button>

                            <ConfirmationModal toggle={this.deleteModalToggle}
                                               onAccept={this.deleteReport}
                                               modal={deleteModal}
                                               header={'Are you sure you want to delete this Fault Code?'}
                                               body={''}
                                               showButton={true}
                            />
                        </div>}
                </td>
            </tr>
        )
    }
}

export default FaultCodeRow
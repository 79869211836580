import React, { Component } from "react";
import { compose } from "recompose";
import "./Booking.css";
import { withRouter } from "react-router-dom";
import { withAuthorization } from "../../session/index";
import Grid from "../../components/booking/Grid/Grid";
import { SearchIcon } from "../../components/elements/Icons/Icons";
import { Button, FormGroup, Input } from "reactstrap";
import DatePicker from "react-datepicker/es";
import IntlTelInput from "react-intl-tel-input";
import DateSelector from "../../components/booking/DateSelector/DateSelector";
import Inspection from "../../components/booking/Inspection/Inspection";
class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchBar: false,
      startDate: null,
      filteredHits: []
    };
  }

  // showSearchBarHandler = () => {
  //   const { updateGrid } = this.props;
  //   this.setState(
  //     prevState => ({
  //       showSearchBar: !prevState.showSearchBar
  //     }),
  //     () => {
  //       const { showSearchBar } = this.state;
  //       if (!showSearchBar) {
  //         updateGrid(() => {});
  //       }
  //     }
  //   );

  //   const inputKey = Object.keys(this.props.search);
  //   inputKey.forEach(key => {
  //     this.props.onInputChange({
  //       target: {
  //         name: key,
  //         value: ""
  //       }
  //     });
  //   });
  //   // this.props.searchEvaluation();
  // };

  removeDuplicates = myArr => {
    return Array.from(new Set(myArr.map(a => a.inspectionRef))).map(
      inspectionRef => {
        return myArr.find(a => a.inspectionRef === inspectionRef);
      }
    );
  };

  render() {
    const {
      evaluations,
      activeBasicBooks,
      activeAdvancedBooks,
      search,
      handleChange,
      onInputChange,
      searchEvaluation,
      noSearchResults,
      setDateRange,
      dateRange
    } = this.props;
    const { showSearchBar } = this.state;
    return (
      <div id={"booking"}>
        <h1 style={{ borderBottom: "3px solid black", paddingBottom: "10px" }}>
          {" "}
          Evaluations{" "}
        </h1>
        {/* <p>{activeBasicBooks ? 'Basic' : activeAdvancedBooks ? 'Advanced' : 'All'}</p> */}
        <div className="d-flex justify-content-between align-items-start">
          {/* <div
            className="search-button mr-2"
            onClick={() => this.showSearchBarHandler()}
          >
            <div className={"search-icon"}>
              <SearchIcon />
            </div>
            Show Search
          </div> */}
          {/* {!showSearchBar && <DateSelector setDateRange={setDateRange} dateRange={dateRange}/>} */}
        </div>
        {/* {showSearchBar && (
          <div className="search-bar grid">
            <Input
              name="text"
              value={search.text}
              className="search"
              placeholder="Search"
              onChange={event => onInputChange(event)}
            />
          </div>
        )} */}
        {/* <IntlTelInput
                        preferredCountries={['ae']}
                        inputClassName={'form-control'}
                        inputName={'ownerPhoneNumber'}
                        inputId={'ownerInputPhoneNumber'}
                        separateDialCode={true}
                        onPhoneNumberChange={(status, value, countryData, number, id) => {
                            onInputChange({
                                target: {
                                    name: 'phone',
                                    value: value
                                }
                            });
                            onInputChange({
                                target: {
                                    name: 'phoneCode',
                                    value: value === "" ? "" : countryData.dialCode
                                }
                            });
                        }}
                        value={search.phone}
                    />
                    <DatePicker
                        selected={search.date}
                        onChange={handleChange}
                        className=""
                        placeholderText="Search by Time"
                    />
                    {/* <Input name={'reference'}
                           value={search.reference}
                           className='small'
                           placeholder={'Booking Reference'}
                           onChange={event => onInputChange(event)}
                    /> */}
        {/* <Input name={'plate'}
                           value={search.plate}
                           className='small'
                           placeholder={'Search by Plate'}
                           onChange={event => onInputChange(event)}
                    />
                    <Input name={'vin'}
                           value={search.vin}
                           className='small'
                           placeholder={'Search by VIN'}
                           onChange={event => onInputChange(event)}
                    /> */}
        {/* <Button
                        color="secondary"
                        className="search-btn"
                        onClick={() => searchEvaluation()}
                    >
                        Search
                    </Button> */}

        <Grid
          noSearchResults={noSearchResults}
          evaluations={evaluations}
          onInputChange={onInputChange}
          search={search}
          value={showSearchBar}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withRouter
)(Booking);

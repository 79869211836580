import React, {Component} from "react";
import {compose} from "recompose";
import './DateSelector.css'
import {withAuthorization} from "../../../session/index";
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, FormGroup, Label} from 'reactstrap';
import moment from "moment";
import DatePicker from "react-datepicker/es";

class DateSelector extends Component {
    state = {
        displayPlaceholderOnCustomDateRange: true,
        timeRanges: {
            today: {
                title: 'Today',
                active: true,
                fromDate: new Date(),
                toDate: new Date()
            },
            tomorrow: {
                title: 'Tomorrow',
                active: false,
                fromDate: moment().add(1, 'days').toDate(),
                toDate: moment().add(1, 'days').toDate()
            },
            yesterday: {
                title: 'Yesterday',
                active: false,
                fromDate: moment().subtract(1, 'days').toDate(),
                toDate: moment().subtract(1, 'days').toDate()
            },
            last7Days: {
                title: 'Last 7 Days',
                active: false,
                fromDate: moment().subtract(7, 'days').toDate(),
                toDate: new Date()
            },
            next7Days: {
                title: 'Next 7 Days',
                active: false,
                fromDate: moment().add(1, 'days').toDate(),
                toDate: moment().add(7, 'days').toDate()
            },
            lastMonth: {
                title: 'Last Month',
                active: false,
                fromDate: moment().subtract(1, 'month').startOf('month').toDate(),
                toDate: moment().subtract(1, 'month').endOf('month').toDate()
            },
            thisMonth: {
                title: 'This Month',
                active: false,
                fromDate: moment().startOf('month').toDate(),
                toDate: moment().endOf('month').toDate()
            },
            customDateRange: {
                title: 'Custom Range',
                active: false,
                fromDate: new Date(),
                toDate: moment().endOf('month').toDate()
            }
        },
        dropdownOpen: false
    };

    dropDownToggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };

    handleFromChange = (data) => {
        const {timeRanges} = this.state;
        timeRanges.customDateRange.fromDate = data;
        this.setState({timeRanges, displayPlaceholderOnCustomDateRange: false},
            () => {
                const {setDateRange} = this.props;
                setDateRange(timeRanges.customDateRange.fromDate, timeRanges.customDateRange.toDate);
            }
        )
    };

    handleToChange = (data) => {
        const {timeRanges} = this.state;
        timeRanges.customDateRange.toDate = data;
        this.setState({timeRanges, displayPlaceholderOnCustomDateRange: false},
            () => {
                const {setDateRange} = this.props;
                setDateRange(timeRanges.customDateRange.fromDate, timeRanges.customDateRange.toDate);
            }
        )
    };

    changeActiveRange = (key) => {
        const {timeRanges} = this.state;
        const timeRangesKeys = Object.keys(timeRanges);
        timeRangesKeys.forEach(element => {
            timeRanges[element].active = element === key;
        });
        this.setState({displayPlaceholderOnCustomDateRange: true});
    };

    isOneDay = (fromDate, toDate) => {
        return fromDate.getFullYear() === toDate.getFullYear() && fromDate.getMonth() === toDate.getMonth() && fromDate.getDate() === toDate.getDate();
    };

    render() {
        const {setDateRange, dateRange} = this.props;
        const {timeRanges, dropdownOpen, displayPlaceholderOnCustomDateRange} = this.state;
        const timeRangesKeys = Object.keys(timeRanges);
        const showDate = this.isOneDay(dateRange.fromDate, dateRange.toDate)
            ? `${dateRange.fromDate.toLocaleDateString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric'})}`
            : `${dateRange.fromDate.toLocaleDateString("en-GB", {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            })} - ${dateRange.toDate.toLocaleDateString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric'})}`;
        return (
            <div className={'date-selector'}>
                {timeRanges.customDateRange.active && <div className='d-flex justify-content-between mr-2'>
                    <FormGroup className='d-flex flex-column mr-2'>
                        <DatePicker
                            selected={displayPlaceholderOnCustomDateRange ? '' : timeRanges.customDateRange.fromDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={this.handleFromChange}
                            placeholderText="From"
                        />
                    </FormGroup>
                    <FormGroup className='d-flex flex-column'>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={displayPlaceholderOnCustomDateRange ? '' : timeRanges.customDateRange.toDate}
                            onChange={this.handleToChange}
                            placeholderText="To"
                        />
                    </FormGroup>
                </div>
                }
                <ButtonDropdown isOpen={dropdownOpen} toggle={this.dropDownToggle}>
                    <DropdownToggle caret color="danger">
                        {showDate}
                    </DropdownToggle>
                    <DropdownMenu>
                        {timeRangesKeys && timeRanges && timeRangesKeys.map((element, index) => (
                            <div key={index}>
                                <DropdownItem
                                    className={`${timeRanges[element].active && 'active'}`}
                                    disabled={timeRanges[element].active}
                                    onClick={() => {
                                        this.changeActiveRange(element);
                                        setDateRange(timeRanges[element].fromDate, timeRanges[element].toDate);
                                    }}
                                    key={index}>{timeRanges[element].title}
                                </DropdownItem>
                            </div>
                        ))}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        );
    }
}

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition)
)(DateSelector);

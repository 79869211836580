import React from "react";
import {Route, Switch} from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import {withAuthentication} from "../../session/index";

import "bootstrap/dist/css/bootstrap.min.css";

import Landing from "../Landing/index";
import Main from "../Main/Main"

const App = () => (
    <div>
        <Switch>
            <Route exact path={ROUTES.LANDING} component={Landing}/>
            <Route exact path={ROUTES.BOOKING} component={Main}/>
            <Route exact path={ROUTES.CREATE} component={Main}/>
            <Route exact path={ROUTES.EDIT} component={Main}/>
            <Route exact path={ROUTES.ADMIN} component={Main}/>
            <Route component={Landing}/>
        </Switch>
    </div>
);

export default withAuthentication(App);

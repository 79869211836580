import app from "@firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import "@firebase/storage";
import "@firebase/functions";

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

// const fireStoreSettings = {
//     timestampsInSnapshots: true
// };
console.log("configconfig",config)

class Firebase {
    constructor() {
        app.initializeApp(config);
        /* Firebase APIs */
        this.auth = app.auth();
        this.fire = app.firestore();
        // this.fire.settings(fireStoreSettings);
        this.storage = app.storage();
        this.func = app.functions();

    }
}

export default Firebase;

const onlyNumbersRegex = /^[0-9.]+$/;
const phoneRegex = /[+()\d-]+$/;
const alphabet = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"];

const ifStringEmpty = (text) => {
    if (text == null) {
        return true;
    }

    text = text.trim();
    return (text.length === 0 && !text.trim() && text.trim() !== ' ');
};

const setStartDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 1, 0);
};

const setEndDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 0);
};

const randomKey = (length) => {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
};

const getRandomColor = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const getAlphabetLetterByIndex = index => {
    return alphabet.length > index && index > -1 ? alphabet[index] : null;
};

const isStringValid = (string) => {
    let regex = new RegExp(/^([A-Za-z][\s]?)+$/)
    return regex.test(string);
}
const isFaultCategoryNameValid = (string) => {
    return new RegExp(/^([A-Za-z][\s]?[-]?[\s]?)+$/).test(string);
}
const isCategoryNameValid = (string) => {
    return new RegExp(/([A-Za-z][\s]?[-|&]?[\s]?)+$/).test(string);
}
const isColorValid = string => {
    return new RegExp(/^[a-zA-Z0-9 .!?"-]+$/).test(string);
}
const isPlateEngineNumberValid = string => {
    return new RegExp(/^[A-Za-z0-9]+$/).test(string);
}
const isEmailValid = email => {
    return new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/).test(email);
}

const isNumberInvalid = number => {
    return isNaN(number);
}

const getLocaleDateString = (seconds) => {
    const theDate = new Date(seconds * 1000);
    return theDate.toLocaleDateString("en-GB", {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
}

export {ifStringEmpty, getLocaleDateString, isCategoryNameValid, randomKey, isFaultCategoryNameValid, isPlateEngineNumberValid, alphabet, isStringValid, isColorValid, isEmailValid, isNumberInvalid, getRandomColor, onlyNumbersRegex, phoneRegex, getAlphabetLetterByIndex, setStartDay, setEndDay};
 
import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

const style = {
    position: 'relative',
    width: '100%',
    height: '200px',
};

export class MapContainer extends Component {

    onMarkerDragEnd = (mapProps, map, event) => {
        const {setLocation} = this.props;
        const latitude = event.latLng.lat()
        const longitude = event.latLng.lng()
        fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + latitude + ',' + longitude + '&key=' + 'AIzaSyDQdAIFq49oyAOuWwi5M9XpY8kq7z7_RgE')
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson && responseJson.results && responseJson.results.length > 0) {
                const address = responseJson.results[0].formatted_address;
                setLocation(address, {
                    lat: latitude,
                    lng: longitude
                });

            }
        })
    }
    render() {
        const {lat, lng} = this.props;
        return (
            <Map
                google={this.props.google}
                zoom={14}
                style={style}
                initialCenter={{
                    lat: lat ? lat : 25.1972,
                    lng: lng ? lng : 55.2744,
                }}
                center={{
                    lat: lat ? lat : 25.1972,
                    lng: lng ? lng : 55.2744,
                }}

            >
                <Marker
                    name={'Current location'}
                    position={
                        {
                            lat: lat ? lat : 25.1972,
                            lng: lng ? lng : 55.2744,
                        }
                    }
                    onClick={this.onMarkerClick}
                    draggable={true}
                    onDragend={this.onMarkerDragEnd}
                />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'GOOGLE_API_KEY'
})(MapContainer)
import React from 'react';
import './DotLine.css';

const DotLine = props => {
    return(
        <div className="dotLine">
            <div className='bigDot'/>
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
            {/*<div className='littleDot'/>*/}
            {/*<div className='bigDot'/>*/}
        </div>
    )
};

export default DotLine